export default class GTM {
  trackThankYou(data) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "conversion",
        ecommerce: {
          value: data.amount,
          currency: data.currency,
          transaction_id: data.transactionId,
        },
        amount: data.amount,
        currency: data.currency,
        transactionId: data.transactionId,
      });
    }
  }

  trackDonationDetails(data) {
    var deviceType = this.getDeviceType(window.navigator.userAgent);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "doantionDetailsPage",
        ecommerce: {
          currency: "INR",
          site: deviceType,
          items: [
            {
              item_id: data.id,
              item_name: data.slug,
              event: data.event,
              path: "/donation-details/" + data.slug,
              title: data.name,
              org: data.org,
            },
          ],
          detail: {
            actionField: {
              campaignCode: data.slug,
              list: "",
            }, // 'detail' actions have an optional list property.
            products: [
              {
                name: data.slug, // Name or ID is required.
                id: data.id,
                event: data.event,
                path: "/donation-details/" + data.slug,
                title: data.name,
                org: data.org,
              },
            ],
          },
        },
      });
    }
  }

  trackPujaDetails(data) {
    var deviceType = this.getDeviceType(window.navigator.userAgent);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pujaDetailsPage",
        ecommerce: {
          currency: "INR",
          site: deviceType,
          items: [
            {
              item_id: data.id,
              item_name: data.slug,
              event: data.event,
              path: "/pujas/" + data.slug,
              title: data.name,
              org: data.org,
            },
          ],
          detail: {
            actionField: {
              campaignCode: data.slug,
              list: "",
            }, // 'detail' actions have an optional list property.
            products: [
              {
                name: data.slug, // Name or ID is required.
                id: data.id,
                event: data.event,
                path: "/pujas/" + data.slug,
                title: data.name,
                org: data.org,
              },
            ],
          },
        },
      });
    }
  }

  trackPaymentSuccess(data, event = "PaymentSuccess") {
    if (window.dataLayer) {
      var deviceType = this.getDeviceType(window.navigator.userAgent);

      window.dataLayer.push({
        event: event,
        ecommerce: {
          transaction_id: data.transactionId,
          currency: data.currency,
          value: data.amount,
          user_name: data.userName || "",
          site: deviceType,
          company: "Veda Sankalpa",
          items: [
            {
              item_name: data.slug, // Name or ID is required.
              title: data.name || "",
              item_id: data.itemId,
              amount: data.amount,
              item_event: data.event,
              coupon: "",
            },
          ],
          purchase: {
            actionField: {
              id: data.transactionId, // Transaction ID. Required for purchases and refunds.
              affiliation: "Online Store",
              revenue: data.amount, // Total transaction value (incl. tax and shipping)
              tax: "0.00",
              shipping: "0.00",
              coupon: "",
            },
            products: [
              {
                name: data.slug, // Name or ID is required.
                title: data.name || "",
                id: data.itemId,
                amount: data.amount,
                event: data.event,
              },
            ],
          },
        },
      });
    }
  }

  trackHomePage() {
    var deviceType = this.getDeviceType(window.navigator.userAgent);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "homePage",
        site: deviceType,
        page_title: "homepage",
        page_path: "/",
      });
    }
  }

  initiateCheckout(order) {
    var deviceType = this.getDeviceType(window.navigator.userAgent);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "initiateCheckout",
        ecommerce: {
          currency: "INR",
          value: order.totalAmount,
          items: [
            {
              item_id: order.sevaId,
              item_name: order.slug,
              price: order.totalAmount / order.quantity,
              quantity: order.quantity,
              amount: order.totalAmount,
              title: order.name,
            },
          ],
          email: order.email || "",
          site: deviceType,
          checkout: {
            actionField: {
              name: order.slug,
              quantity: order.quantity,
              amount: order.totalAmount,
            },
            products: [
              {
                name: order.slug,
                price: order.totalAmount / order.quantity,
                amount: order.totalAmount,
                quantity: order.quantity,
                title: order.name,
                items: order.products,
              },
            ],
          },
        },
        eventCallback: function () {},
      });
    }
  }

  trackUserLogin(data) {
    var deviceType = this.getDeviceType(window.navigator.userAgent);

    window.dataLayer.push({
      event: "userLogin",
      ecommerce: {
        username: data.username || "",
        site: deviceType,
      },
    });
  }

  getDeviceType(userAgent) {
    return /iPad/.test(userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(userAgent)
      ? "m"
      : "d";
  }
}
