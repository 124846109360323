import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Special_offer from "./Special_offer";
import { Container } from "@mui/material";
import "../styles/components/log_in.css";
import SevaBookingSuccessfulImage from "../assets/images/check_out/mycart/seva-booking-successful-image.svg";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "../styles/pages/home.css";
import { CloseOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

// const firstModalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   height: "400px",
// };

function DonationSuccessfull({ openModal }) {
  const navigate = useNavigate();
  const [firstModalOpen, setFirstModalOpen] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (openModal) {
      // Open the modal immediately
      setFirstModalOpen(true);
      // Close the modal after 5 seconds
      timeoutId = setTimeout(() => {
        setFirstModalOpen(false);
        navigate("/"); // Navigate after modal closes
      }, 10000);
    }

    // Clear the timeout when the component unmounts or when modal is closed manually
    return () => clearTimeout(timeoutId);
  }, [openModal, navigate]);

  const handleClose = () => {
    navigate("/");
    setFirstModalOpen(false);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Dialog
      className="new-bg"
      open={firstModalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby="alert-dialog-slide-description"
    >
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.common.white,
        }}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 60,
            }}
          >
            <div className="Seva-Booking">
              <img
                src={SevaBookingSuccessfulImage}
                alt="Seva Booking Successful"
              />
              <div className="Seva-Booking-text">
                <Typography variant="h5">Donated Successfully</Typography>
                <Typography variant="body1">
                  We have successfully received your details
                </Typography>
              </div>
              <Typography variant="h6">
                You will get a confirmation call or WhatsApp within 12 hrs of
                booking
              </Typography>
            </div>
            {/* <div className="succes-boxing-bg">
         <h2>Our Special offer this Season</h2>
         <p>Lorem Epson is a dummy text to fill the sentencesLorem Epson is a dummy text to fill the sentences</p>
       </div> */}
          </div>
          <section className="our-special-offerss">
            <div className="our-special-offers-bg">
              <Special_offer />
            </div>
          </section>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default DonationSuccessfull;
