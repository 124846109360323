import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../styles/components/donors.css";
import first_top_recent_donors from "../assets/images/icons/donor.png";
import anonymous_donors from "../assets/images/icons/anonymous.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { noauthinstance } from "../utils/api";
import { useParams } from "react-router-dom";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Donors() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const [displayedItems, setDisplayedItems] = useState(6);
  const [viewGenerous, setViewGenerous] = useState(false);

  const handleClickOpen = (isGenerous) => {
    setOpen(true);
    setViewGenerous(isGenerous);
    // setDisplayedItems(displayedItems + 6);
    
    setCurrentPage(1);
  };
  const handleClose = () => {
    // setDisplayedItems(6);
    setCurrentPage(0);
    setViewMoreDonations([]);
    setOpen(false);
  };

  const { slug } = useParams();
  const [recentDonations, setRecentDonations] = useState();
  const [generousDonations, setGenerousDonations] = useState();
  const [viewMoreDonations, setViewMoreDonations] = useState([]);
  const [noOfDonors, setNoOfDonors] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handleViewMore = () => {
    // setDisplayedItems(displayedItems + 6);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  async function getViewDonation() {
    if(currentPage === 0) return;
    try {
      let response 
      console.log(viewGenerous);
      if(viewGenerous){
        response = await noauthinstance.get(
          `donation_orders_top/${slug}/?page=${currentPage}&page_size=10`
        );
      }
      else {response = await noauthinstance.get(
        `donationAllOrders/${slug}/?page=${currentPage}&page_size=10`
      );}
      if (response.status === 200) {
        setNoOfDonors(response.data.count);
        setViewMoreDonations((prevDonations) => [
          ...prevDonations,
          ...response.data.results,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getDonation() {
    try {
      const response = await noauthinstance.get(`donation_orders/${slug}`);
      const response2 = await noauthinstance.get(
        `donation_orders_recent/${slug}`
      );
      console.log(response);
      if (response.status === 200) {
        console.log(response.data);

        setRecentDonations(response2.data.recent_orders);
        setGenerousDonations(response.data.all_orders);

        console.log(
          response.data.all_orders[0].order_seva_donation[0].created_at
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDonation();
  }, []);

  useEffect(() => {
    getViewDonation();
  }, [currentPage]);

  const calculateDaysAgo = (orderDate) => {
    const orderDateObj = new Date(orderDate);
    const currentDate = new Date();
    const differenceMs = currentDate - orderDateObj;

    const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
    const differenceHours = Math.floor(differenceMs / (1000 * 60 * 60));
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    if (differenceDays >= 1) {
      return `${differenceDays} ${differenceDays > 1 ? "days" : "day"} ago`;
    } else if (differenceHours >= 1) {
      return `${differenceHours} ${differenceHours > 1 ? "hours" : "hour"} ago`;
    } else {
      return `${differenceMinutes} ${
        differenceMinutes > 1 ? "minutes" : "minute"
      } ago`;
    }
  };

  const options = { maximumFractionDigits: 2 };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Recent" {...a11yProps(0)} />
            <Tab label="Most Generous" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="main_recent_generous">
            <div className="recent_mostgeneous">
              {recentDonations &&
                recentDonations.slice(0, 6).map((order, index) => (
                  <div className="recent_mostgeneous_list" key={index}>
                    <div className="recent_mostgeneous_list_items">
                      {order.is_anyomouns === true ? (
                        <img src={anonymous_donors} />
                      ) : (
                        <img src={first_top_recent_donors} />
                      )}
                    </div>
                    <div className="recent_mostgeneous_list_content">
                      <h3>
                        {order.is_anyomouns === false
                          ? `${
                              JSON.parse(
                                order.personal_details.replace(/'/g, '"')
                              ).firstName
                            } ${
                              JSON.parse(
                                order.personal_details.replace(/'/g, '"')
                              ).lastName
                            }`
                          : "Anonymous"}
                      </h3>
                      <h5>
                        Donated ₹
                        {Intl.NumberFormat("en-IN", options).format(
                          Math.round(order.original_amount)
                        )}
                      </h5>
                      <p>
                        {calculateDaysAgo(
                          order.order_seva_donation[0].created_at
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              {/* <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={first_top_recent_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Prabhas Raju</h3>
                  <h5>Donated ₹5,000</h5>
                  <p>1 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={first_top_recent_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Karthik Kumar</h3>
                  <h5>Donated ₹6,000</h5>
                  <p>2 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={anonymous_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Anonymous</h3>
                  <h5>Donated ₹8,000</h5>
                  <p>1 day ago</p>
                </div>
              </div> */}
            </div>
            {/* <div className="recent_mostgeneous">
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={first_top_recent_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Pawan Kalyan</h3>
                  <h5>Donated ₹5,000</h5>
                  <p>1 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={anonymous_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Anonymous</h3>
                  <h5>Donated ₹2,000</h5>
                  <p>2 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={anonymous_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Anonymous</h3>
                  <h5>Donated ₹2,000</h5>
                  <p>3 day ago</p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="more_donors">
            <Button disableRipple disableElevation>
              <React.Fragment>
                {noOfDonors > 6 && (
                  <Button
                    disableRipple
                    disableElevation
                    className="donors_more"
                    onClick={() => handleClickOpen(false)}
                  >
                    View More
                  </Button>
                )}

                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  className="donar_main_modal"
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    All Donors{" "}
                    <span>
                      ( {Intl.NumberFormat("en-IN").format(noOfDonors)} )
                    </span>
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      <div className="modal_donors">
                        <div className="modal_donors_list">
                          {viewMoreDonations &&
                            viewMoreDonations.map((order, index) => (
                              <div
                                className="recent_mostgeneous_list"
                                key={index}
                              >
                                <div className="recent_mostgeneous_list_items">
                                  {order.is_anyomouns === true ? (
                                    <img src={anonymous_donors} />
                                  ) : (
                                    <img src={first_top_recent_donors} />
                                  )}
                                </div>
                                <div className="recent_mostgeneous_list_content">
                                  <h3>
                                    {order.is_anyomouns === false
                                      ? `${
                                          JSON.parse(
                                            order.personal_details.replace(
                                              /'/g,
                                              '"'
                                            )
                                          ).firstName
                                        } ${
                                          JSON.parse(
                                            order.personal_details.replace(
                                              /'/g,
                                              '"'
                                            )
                                          ).lastName
                                        }`
                                      : "Anonymous"}
                                  </h3>
                                  <h5>
                                    Donated ₹
                                    {Intl.NumberFormat("en-IN", options).format(
                                      Math.round(order.original_amount)
                                    )}
                                  </h5>
                                  <p>
                                    {calculateDaysAgo(
                                      order.order_seva_donation[0].created_at
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          {/* <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="modal_donors_list">
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={anonymous_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Anonymous</h3>
                              <h5>Donated ₹2,000</h5>
                              <p>2 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={anonymous_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Anonymous</h3>
                              <h5>Donated ₹2,000</h5>
                              <p>2 day ago</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="all_donars_button">
                        {viewMoreDonations.length >= noOfDonors ? null : (
                          <Button
                            disableRipple
                            disableElevation
                            onClick={() => {handleViewMore()}}
                          >
                            View More
                          </Button>
                        )}
                      </div>
                    </Typography>
                  </DialogContent>
                </BootstrapDialog>
              </React.Fragment>
            </Button>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="main_recent_generous">
            <div className="recent_mostgeneous">
              {generousDonations &&
                generousDonations.slice(0, 6).map((order, index) => (
                  <div className="recent_mostgeneous_list" key={index}>
                    <div className="recent_mostgeneous_list_items">
                      {order.is_anyomouns === true ? (
                        <img src={anonymous_donors} />
                      ) : (
                        <img src={first_top_recent_donors} />
                      )}
                    </div>
                    <div className="recent_mostgeneous_list_content">
                      <h3>
                        {order.is_anyomouns === false
                          ? `${
                              JSON.parse(
                                order.personal_details.replace(/'/g, '"')
                              ).firstName
                            } ${
                              JSON.parse(
                                order.personal_details.replace(/'/g, '"')
                              ).lastName
                            }`
                          : "Anonymous"}
                      </h3>
                      <h5>
                        Donated ₹{" "}
                        {Intl.NumberFormat("en-IN", options).format(
                          Math.round(order.original_amount)
                        )}
                      </h5>
                      <p>
                        {calculateDaysAgo(
                          order.order_seva_donation[0].created_at
                        )}
                      </p>
                    </div>
                  </div>
                ))}

              {/* <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={first_top_recent_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Pawan Kalyan</h3>
                  <h5>Donated ₹5,000</h5>
                  <p>1 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={anonymous_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Anonymous</h3>
                  <h5>Donated ₹2,000</h5>
                  <p>2 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={anonymous_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Anonymous</h3>
                  <h5>Donated ₹2,000</h5>
                  <p>3 day ago</p>
                </div>
              </div> */}
            </div>
            {/* <div className="recent_mostgeneous">
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={first_top_recent_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Prabhas Raju</h3>
                  <h5>Donated ₹5,000</h5>
                  <p>1 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={first_top_recent_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Karthik Kumar</h3>
                  <h5>Donated ₹6,000</h5>
                  <p>2 day ago</p>
                </div>
              </div>
              <div className="recent_mostgeneous_list">
                <div className="recent_mostgeneous_list_items">
                  <img src={anonymous_donors} />
                </div>
                <div className="recent_mostgeneous_list_content">
                  <h3>Anonymous</h3>
                  <h5>Donated ₹8,000</h5>
                  <p>1 day ago</p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="more_donors">
            <Button disableRipple disableElevation>
              <React.Fragment>
                {noOfDonors > 6 && (
                  <Button
                    disableRipple
                    disableElevation
                    className="donors_more"
                    onClick={() => handleClickOpen(true)}
                  >
                    View More
                  </Button>
                )}
                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  className="donar_main_modal"
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    All Donors{" "}
                    <span>
                      ({Intl.NumberFormat("en-IN").format(noOfDonors)})
                    </span>
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      <div className="modal_donors">
                        <div className="modal_donors_list">
                          {viewMoreDonations &&
                            viewMoreDonations.map((order, index) => (
                              <div
                                className="recent_mostgeneous_list"
                                key={index}
                              >
                                <div className="recent_mostgeneous_list_items">
                                  {order.is_anyomouns === true ? (
                                    <img src={anonymous_donors} />
                                  ) : (
                                    <img src={first_top_recent_donors} />
                                  )}
                                </div>
                                <div className="recent_mostgeneous_list_content">
                                  <h3>
                                    {order.is_anyomouns === false
                                      ? `${
                                          JSON.parse(
                                            order.personal_details.replace(
                                              /'/g,
                                              '"'
                                            )
                                          ).firstName
                                        } ${
                                          JSON.parse(
                                            order.personal_details.replace(
                                              /'/g,
                                              '"'
                                            )
                                          ).lastName
                                        }`
                                      : "Anonymous"}
                                  </h3>
                                  <h5>
                                    Donated ₹
                                    {Intl.NumberFormat("en-IN", options).format(
                                      Math.round(order.original_amount)
                                    )}
                                  </h5>
                                  <p>
                                    {calculateDaysAgo(
                                      order.order_seva_donation[0].created_at
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}

                          {/* <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="modal_donors_list">
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={anonymous_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Anonymous</h3>
                              <h5>Donated ₹2,000</h5>
                              <p>2 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={first_top_recent_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Pawan Kalyan</h3>
                              <h5>Donated ₹5,000</h5>
                              <p>1 day ago</p>
                            </div>
                          </div>
                          <div className="recent_mostgeneous_list">
                            <div className="recent_mostgeneous_list_items">
                              <img src={anonymous_donors} />
                            </div>
                            <div className="recent_mostgeneous_list_content">
                              <h3>Anonymous</h3>
                              <h5>Donated ₹2,000</h5>
                              <p>2 day ago</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="all_donars_button">
                        {viewMoreDonations.length >= noOfDonors ? null : (
                          <Button
                            disableRipple
                            disableElevation
                            onClick={() => {handleViewMore()}}
                          >
                            View More
                          </Button>
                        )}
                      </div>
                    </Typography>
                  </DialogContent>
                </BootstrapDialog>
              </React.Fragment>
            </Button>
          </div>
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default Donors;
