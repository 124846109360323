import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import "../styles/pages/profile.css";
import profile from "../assets/images/profile/profile.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import user from "../assets/images/profile/profile_og.svg";
import user_bw from "../assets/images/profile/profile_bw.svg";
import order_transaction from "../assets/images/profile/order-transaction.svg";
import download from "../assets/images/profile/80g-dowload.svg";
import address from "../assets/images/profile/address.svg";
import log_out from "../assets/images/profile/log-out.svg";
import Profile_details from "../components/Profile_details";
import Order_transaction from "../components/Order_transaction";
import Myfamily_details from "../components/Myfamily_details";
import Karta_details from "../components/Karta_details";
import Ancestor_details from "../components/Ancestor_details";
import Karta_ancestor_details from "../components/Karta_ancestor_details";
import Address from "../components/Address";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import instance from "../utils/api";
import axios from "axios";
import { useAppContext } from "../components/Auth";
import { useLocation } from "react-router-dom";
import whatsapp from '../assets/images/icons/whatsapp.png'

function Profile() {
  const vedatoken = localStorage.getItem("vedatoken");
  const [profiledata, setProfiledata] = useState([]);
  const {
    customerName,
    setCustomerName,
    setIsLoggedIn,
    customerPhone,
    setCustomerPhone,
    profilePictureData,
  } = useAppContext();
  const id = localStorage.getItem("vedauserid");
  const navigate = useNavigate();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [profileActive, setProfileActive] = useState(null);

  const handleProfile = () => {
    setProfileActive(true);
    setActiveAccordion(null);
  };

  const handleAccordionClick = (accordionId) => {
    setActiveAccordion(accordionId);
  };

  const handlelogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
  };
  const fetchProfilePicture = async () => {
    try {
      const response = await instance.get(`customer/${id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setProfiledata(response.data);
      setCustomerName(
        `${response.data.user.first_name} ${response.data.user.last_name}`
      );
      setCustomerPhone(response.data.user.username);
    } catch (error) {
      console.error("Error fetching profile picture data:", error);
    }
  };

  useEffect(() => {
    fetchProfilePicture();
  }, []);

  //active state
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <Header />
      <section className="profile">
        <Container maxWidth="xl">
          <div className="profile_main">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="profile_menu_bg">
                  <div className="profile_menu">
                    {/* <div className="profile_menu_details">
                                            <div className="profile_menu_details_list">
                                                <img src={profilePictureData ? profilePictureData : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="} />
                                                <h3>{customerName}</h3>
                                                <p>{customerPhone}</p>
                                            </div>
                                        </div> */}
                    <div className="profile_menu_list">
                      <div>
                        <Accordion
                          className={
                            activeAccordion === "Profile"
                              ? "accordion1 active"
                              : "accordion1"
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => handleAccordionClick("Profile")}
                          >
                            <img className="user_bg_main" src={user} /> Profile
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="profile_list">
                              <ul>
                                <li
                                  className={
                                    splitLocation[1] === "profile" &&
                                    splitLocation[2] === "myprofile"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => handleProfile()}
                                >
                                  <Link to="/profile/myprofile">
                                    <img src={user} />
                                    <span>My Profile</span>
                                  </Link>
                                </li>
                                <li
                                  className={
                                    splitLocation[1] === "profile" &&
                                    splitLocation[2] === "myfamily"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => handleProfile()}
                                >
                                  <Link to="/profile/myfamily">
                                    <img src={user} />
                                    <span>My Family</span>
                                  </Link>
                                </li>
                                <li
                                  className={
                                    splitLocation[1] === "profile" &&
                                    splitLocation[2] === "kartha"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => handleProfile()}
                                >
                                  <Link to="/profile/kartha">
                                    <img src={user} />
                                    <span>Karta Details</span>
                                  </Link>
                                </li>
                                <li
                                  className={
                                    splitLocation[1] === "profile" &&
                                    splitLocation[2] === "ancestor"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => handleProfile()}
                                >
                                  <Link to="/profile/ancestor">
                                    <img src={user} />
                                    <span>Ancestor Details</span>
                                  </Link>
                                </li>
                                <li
                                  className={
                                    splitLocation[1] === "profile" &&
                                    splitLocation[2] === "kartha-ancestor"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => handleProfile()}
                                >
                                  <Link to="/profile/kartha-ancestor">
                                    <img src={user} />
                                    <span>Karta Ancestor Details</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          className={
                            activeAccordion === "orderTransaction"
                              ? "accordion1 active"
                              : "accordion1"
                          }
                        >
                          <Link to="/profile/ordertrans">
                            <AccordionSummary
                              aria-controls="panel2-content"
                              id="panel2-header"
                              onClick={() =>
                                handleAccordionClick("orderTransaction")
                              }
                            >
                              <img src={order_transaction} /> Order Transaction
                            </AccordionSummary>
                          </Link>
                        </Accordion>
                        {/* <Accordion
                          className={
                            activeAccordion === "GDownload"
                              ? "accordion1 active"
                              : "accordion1"
                          }
                        >
                          <Link to="/profile/Gdownload">
                            <AccordionSummary
                              aria-controls="panel3-content"
                              id="panel3-header"
                              onClick={() => handleAccordionClick("GDownload")}
                            >
                              <img src={download} /> 80G Download
                            </AccordionSummary>
                          </Link>
                        </Accordion> */}
                        <Accordion
                          className={
                            activeAccordion === "Address"
                              ? "accordion1 active"
                              : "accordion1"
                          }
                        >
                          <Link to="/profile/address">
                            <AccordionSummary
                              aria-controls="panel4-content"
                              id="panel4-header"
                              onClick={() => handleAccordionClick("Address")}
                            >
                              <img src={address} /> <span>Address</span>
                            </AccordionSummary>
                          </Link>
                        </Accordion>
                      </div>
                      <div className="logout">
                        <Button
                          disableRipple
                          disableElevation
                          onClick={handlelogout}
                        >
                          <img src={log_out} />
                          Log-Out
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                <div className="user_right">
                  {/* <Profile_details />   */}
                  {/* <Order_transaction /> */}
                  {/* <Myfamily_details /> */}
                  {/* <Karta_details/> */}
                  {/* <Ancestor_details/> */}
                  {/* <Karta_ancestor_details /> */}
                  {/* <Address/> */}
                  <Outlet />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <Footer />
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
          <img alt="whatsapp" src={whatsapp}/>
      </a>
    </>
  );
}
export default Profile;
