import React, { useEffect, useState,useRef } from "react";
import Header from "../layouts/Header";
import { Box, Button, Container, Stack } from "@mui/material";
import "../styles/pages/explore_puja.css";
import Special_Pujas_Donations from "../assets/images/icons/puja-donation.png";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import Grid from "@mui/material/Grid";
import Special_offer from "../components/Special_offer";
import Footer from "../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import { noauthinstance } from "../utils/api";
import pujaimg from "../assets/images/icons/puja.svg";
import puja_img from "../assets/images/icons/puja-w.png";
import puja2img from "../assets/images/icons/puja-1.svg";
import puja2_img from "../assets/images/icons/donation-w.png";
import whatsapp from '../assets/images/icons/whatsapp.png'
import { useSearchParams } from "react-router-dom";
function Explore_puja() {
  const [displayPuja, setDisplayPuja] = useState(true);
  const [pujas, setPujas] = useState([]);
  const [donations, SetDonations] = useState([]);
  const [displaySeva, setDisplaySeva] = useState(false);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const active_tab = searchParams.get('active_tab');
  const scroll = useRef(null);
  useEffect(() => {
    if(active_tab && scroll.current){
      setTimeout(() => {
      scroll.current.scrollIntoView({ behavior: 'smooth' });
    },250);
    }
    if (active_tab === "donation") setDisplaySeva(false)
    else if (active_tab === "puja") setDisplaySeva(true)
  }, [active_tab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await noauthinstance.get("puja/");
        setPujas(response.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  async function getDonations() {
    try {
      const response = await noauthinstance.get("donation/");

      if (response.status === 200) {
        console.log(response.data.results);
        SetDonations(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDonations();
  }, []);
  const [isReadMore, setIsReadMore] = useState({});

  const toggleReadMore = (pujaId) => {
    setIsReadMore((prevState) => ({
      ...prevState,
      [pujaId]: !prevState[pujaId],
    }));
  };
  const [isReadMore1, setIsReadMore1] = useState({});

  const toggleReadMore1 = (donationId) => {
    setIsReadMore1((prevState) => ({
      ...prevState,
      [donationId]: !prevState[donationId],
    }));
  };
  return (
    <>
      <Header />
      <section className="explore_puja_banner">
        <Container className="donation_cards_container" maxWidth="xl">
          <div className="special_pujas_donation_title">
            {/* <div className="donation_puja_buttons">
              <Button
                disableRipple
                disableElevation
                className={`puja btn ${displayPuja ? "active" : ""}`}
                onClick={() => setDisplayPuja(true)}
              >
                Puja
                <span className="og"><img className="og-img" src={pujaimg} /></span>
                <span className="wh"><img className="wh-img" src={puja_img} /></span>
              </Button>
              <Button
                disableRipple
                disableElevation
                className={`donation btn ${!displayPuja ? "active" : ""}`}
                onClick={() => setDisplayPuja(false)}
              >
                Donation
                <span className="og"><img className="og-img" src={puja2img} /></span>
                <span className="wh"><img className="wh-img" src={puja2_img} /></span>
              </Button>
            </div> */}
            <div className="donation_puja_buttons">
              <Button
                disableRipple
                disableElevation
                className={`donation btn donation_btn1 ${
                  !displaySeva ? "active" : ""
                }`}
                onClick={() => setDisplaySeva(false)}
              >
                Donation
                {/* <span className="og">
                  <img className="og-img" src={puja2img} />
                </span>
                <span className="wh">
                  <img className="wh-img" src={puja2_img} />
                </span> */}
              </Button>
              <Button
                disableRipple
                disableElevation
                className={`puja btn ${displaySeva ? "active" : ""}`}
                onClick={() => setDisplaySeva(true)}
              >
                Puja
                {/* <span className="og">
                  <img className="og-img" src={pujaimg} />
                </span>
                <span className="wh">
                  <img className="wh-img" src={puja_img} />
                </span> */}
              </Button>
            </div>
            <h3>Dharma Parirakshana</h3>
            <p>
              {" "}
              Uphold the Glory of Sanatana Dharma: Support Temples, Veda
              Pathshalas, Goshalas, and Ashrams through your generous donations.
            </p>
          </div>
          {displaySeva ? (
            <div className="explore_puja-boxs">
              <Grid container spacing={2}>
                {pujas.map((puja) => (
                  <Grid
                    key={puja.id}
                    item
                    sx={{ mb: "1rem" }}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <div
                      className="pujas_box"
                      onClick={() => navigate(`/pujas/${puja.slug}`)}
                    >
                      <Stack
                        sx={{ height: "100%" }}
                        justifyContent={"space-between"}
                      >
                        <Box>
                          <div className="puja_featured_image">
                            <img
                              src={`${puja.featured_image.base_url}${puja.featured_image.image_path}`}
                              alt=""
                            />
                          </div>
                          <h3>{puja.short_name}</h3>
                          <p>{puja.short_description.slice(0, 215)}</p>

                          <h4>Location: {puja.location}</h4>
                          <h5>
                            Date:{" "}
                            {new Date(puja.start_date).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </h5>
                        </Box>
                        <div className="book-now">
                          <Link to={`/pujas/${puja.slug}`}>
                            {puja.donation_expired === false ? (
                              <Button
                                disableRipple
                                disableElevation
                                className="btn"
                              >
                                Book Now
                              </Button>
                            ) : (
                              <Button className="btn">View Updates</Button>
                            )}
                          </Link>
                        </div>
                      </Stack>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <div className="explore_puja-boxs">
              <Grid container spacing={2}>
                {donations.map((donation) => (
                  <Grid
                    item
                    sx={{ mb: "1rem" }}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    key={donation.id}
                  >
                    <div
                      className="pujas_box donation_box"
                      onClick={() =>
                        navigate(`/donation/${donation.slug}`)
                      }
                    >
                      {donation.tax_beneficiary && (
                        <Box className="tax-benfits-strip">
                          Tax Benefits Available
                        </Box>
                      )}

                      <Stack
                        sx={{ height: "100%" }}
                        justifyContent={"space-between"}
                      >
                        <Box>
                          <div className="puja_featured_image">
                            <img
                              src={`${donation.featured_image.base_url}${donation.featured_image.image_path}`}
                              alt=""
                            />
                          </div>
                          <h3>{donation.short_name}</h3>
                          <p>{donation.short_description.slice(0, 215)}</p>
                          <h4>Org / Trust :<span>{donation.temple.name}</span></h4>
                        </Box>
                        <div className="book-now">
                          <Link to={`/donation/${donation.slug}`}>
                            {donation.donation_expired === false ? (
                              <Button className="btn">I Donate</Button>
                            ) : (
                              <Button className="btn">View Updates</Button>
                            )}
                          </Link>
                        </div>
                      </Stack>
                    </div>
                  </Grid>
                ))}
                {/* 
                    <Grid item sx={{ mb: '1rem' }} sm={12} md={6} lg={4} xl={4}>
                    <div className='pujas_box donation_box'>
                         <img src={Special_Pujas_Donations} alt="" />
                                <h3>Donation Name</h3>
                                <p>Thai Pusam Special Rituals in Tiruvannamalai: Subramanya Moo..</p>
                                <h4>Org / Trust: Lorem Epson</h4>
                            <div className='book-now'>
                           <Link to='/donation'>
                                    <Button className='btn'>
                                    Donate Now
                                    <img src={rightarrow}/>
                                    </Button>
                                    </Link>
                            </div>
                        </div>
                    </Grid> */}
              </Grid>
            </div>
          )}
        </Container>
      </section>
      <section className="our-special-offers">
        <Container>
          <div className="our-special-offers-bg">
            <Special_offer />
          </div>
        </Container>
      </section>
      <Footer />
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
          <img alt="whatsapp" src={whatsapp}/>
      </a>
    </>
  );
}
export default Explore_puja;
