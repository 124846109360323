import React, { useEffect, useRef, useState } from "react";
import Header from "../layouts/Header";
import { Container, Grid } from "@mui/material";
import "../styles/pages/details_donation.css";
import Products from "../components/Products";
import Sevacard_details from "../components/Sevacard_details";
import { Button, Typography } from "@mui/material";
import Video from "../components/Video";
import Updates from "../components/Updates";
import Recommended_seva from "../components/Recommended_seva";
import "../styles/pages/details_puja.css";
import Product_items from "../components/Product_items";
import Foundation_construct from "../components/Foundation_construct";
import Footer from "../layouts/Footer";
import { Directions } from "@mui/icons-material";
import { useParams } from "react-router";
import { noauthinstance } from "../utils/api";
import { useAppContext } from "../components/Auth";
import "../styles/pages/details_puja.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Pagination, Navigation} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Donors from "../components/Donors";
import donation_image from "../assets/images/details_donation/updates.png";
import whatsapp from "../assets/images/icons/whatsapp.png";
import ReactPlayer from "react-player";
import GTM from "../utils/gtm";
import { CircularProgress } from "@mui/material";
// import donation_new_bg from '../assets/images/details_puja/new_bg.webp'

function Details_donation() {
  const [visibleSection, setVisibleSection] = useState("section1"); //scrollspy
  const [donation, SetDonation] = useState([]);
  const [totalDonation, setTotalDonation] = useState();

  const { donationAmount, setDonationAmount } = useAppContext();
  const [productAmount, setProductAmount] = useState(0);
  const [isDonationEdited, setIsDonationEdited] = useState(false);
  const { isLoggedIn, setIsLoggedIn, open, setOpen, phone, setPhone } =
    useAppContext();
  const [countersReset, setCountersReset] = useState(0);
  const [topDonation, setTopDonation] = useState();
  const [days, setDaysAgo] = useState();
  const [stripFname, setFName] = useState();
  const [stripLname, setLName] = useState();
  const [noDonors, setNoDonors] = useState();
  const [donations, setDonations] = useState();
  const [minimumDonation, setMinimum] = useState(false);
  const [minimumAmount, setMinimumAmount] = useState();
  const Navigate = useNavigate();
  const updateDonationAmount = (amount) => {
    setDonationAmount(amount);
  };
  // const resetCounters = () => {
  //     console.log(countersReset)
  //     setCountersReset(prev => !prev);
  // };
  const { slug } = useParams();

  const calculateTimeDifference = (orderDate) => {
    const orderDateObj = new Date(orderDate);
    const currentDate = new Date();
    const differenceMs = currentDate - orderDateObj;

    const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
    const differenceHours = Math.floor(differenceMs / (1000 * 60 * 60));
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    if (differenceDays >= 1) {
      return `${differenceDays} ${differenceDays > 1 ? "days" : "day"} ago`;
    } else if (differenceHours >= 1) {
      return `${differenceHours} ${differenceHours > 1 ? "hours" : "hour"} ago`;
    } else {
      return `${differenceMinutes} ${differenceMinutes > 1 ? "minutes" : "minute"
        } ago`;
    }
  };

  async function getDonation() {
    try {
      const response = await noauthinstance.get(`donation/${slug}`);
      const response1 = await noauthinstance.get(`donation_amount/${slug}`);

      if (response.status === 200) {
        console.log(response.data);
        try {
          if (response.data.donation_expired === true) {
            // setVisibleSection("section4");
            // handleNavClick(section4Ref);
            setTimeout(() => {
              handleNavClick_updates(section4Ref);
            }, 1000);
          }
        } catch (error) {
          console.log(error);
        }
        SetDonation(response.data);
        console.log(response.data.minimum_amount);
        response.data.minimum_amount == null ||
          response.data.minimum_amount == 0
          ? setMinimumAmount(50)
          : setMinimumAmount(response.data.minimum_amount);

        setTotalDonation(response1.data.total_final_amount);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getDonors = async () => {
    try {
      const response1 = await noauthinstance.get(`donation_amount/${slug}`);
      const response2 = await noauthinstance.get(
        `donation_orders_recent/${slug}`
      );
      const response3 = await noauthinstance.get(
        `donationAllOrders/${slug}/?page=1&page_size=10`
      );
      const currentDate = new Date();
      if (response1.status === 200) {
        const recentDonations = response2.data.recent_orders.map((order) => ({
          ...order,
          timeAgo: calculateTimeDifference(
            order.order_seva_donation[0].created_at
          ),
        }));
        // console.log(recentDonations);
        setDonations(recentDonations);

        setTotalDonation(response1.data.total_final_amount);
        setTopDonation(response2.data.recent_orders[0]);
        const orderDateObj = new Date(
          response2.data.recent_orders[0].order_seva_donation[0].created_at
        );
        const differenceMs = currentDate - orderDateObj;

        // Convert milliseconds to days
        const daysAgo = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
        setDaysAgo(daysAgo);
        // console.log(daysAgo);
        const personalDetails = JSON.parse(
          response2.data.recent_orders[0].personal_details.replace(/'/g, '"')
        );
        setFName(
          response2.data.recent_orders[0].is_anyomouns === false
            ? personalDetails.firstName
            : "Anonymous"
        );
        setLName(
          response2.data.recent_orders[0].is_anyomouns === false
            ? personalDetails.lastName
            : null
        );
        setNoDonors(response3.data.count);
        // console.log(response2.data.recent_orders.length);
        // console.log(response2.data.recent_orders.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDonation();
  }, []);

  useEffect(() => {
    getDonors();
  }, []);

  useEffect(() => {
    if (donation.id) {
      new GTM().trackDonationDetails({
        id: donation.id,
        slug: donation.slug,
        name: donation.name,
        event: donation.event,
        org: donation.temple?.name,
      });
    }
  }, [donation]);

  //scrollspy
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section45Ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionRefs = [
        { ref: section1Ref, name: "section1" },
        { ref: section2Ref, name: "section2" },
        { ref: section3Ref, name: "section3" },
        { ref: section4Ref, name: "section4" },
        { ref: section45Ref, name: "section45" },
      ];
      const currentScrollPosition = window.scrollY;
      for (let i = 0; i < sectionRefs.length; i++) {
        const section = sectionRefs[i];
        if (section.ref.current) {
          const sectionTop = section.ref.current.offsetTop - 125;
          const sectionBottom = sectionTop + section.ref.current.clientHeight;

          if (
            currentScrollPosition >= sectionTop &&
            currentScrollPosition <= sectionBottom
          ) {
            setVisibleSection(section.name);
          }
        }
      }
    };

    const handleResize = () => {
      // Your code for handling resize, similar to your previous implementation
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial height measurement
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavClick = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 120,
      behavior: "smooth",
    });
    // console.log(ref.current.offsetTop);
    setVisibleSection(ref.current.dataset.section);
  };
  const handlecheck = () => {
    // console.log(isLoggedIn);
    // console.log(donationAmount)
    if (donationAmount < minimumAmount) {
      console.log(minimumAmount);
      setMinimum(true);
    } else {
      Navigate(`/checkout/${donation.slug}/${donation.id}`);
    }
  };

  const handleNavClick_updates = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 120,
      behavior: "smooth",
    });
    // console.log(ref.current.offsetTop);
    setVisibleSection(ref.current.dataset.section);
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % noDonors);
    }, 5000);
    return () => clearInterval(interval);
  }, [donations]);

  const currentDonation = donations ? donations[currentIndex] : null;
  const personalDetails = currentDonation?.personal_details
    ? JSON.parse(currentDonation.personal_details.replace(/'/g, '"'))
    : { firstName: "", lastName: "" };

  const formatDateToIndian = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  const total_percentage =
    totalDonation > donation.target_amount
      ? ((totalDonation - donation.target_amount) / donation.target_amount) *
        100
      : (100 * totalDonation) / donation.target_amount;
  const rounded_percentage = Math.round(total_percentage);

  const options = { maximumFractionDigits: 2 };
  return (
    <>
      {donation.name ? (
        <>
          <Header />
          <section className="donation_bg_main">
            {/* <div
              className="donation_bg"
              style={{
                backgroundImage: donation.banner_image
                  ? `url(${donation.banner_image.base_url}${donation.banner_image.image_path})`
                  : "",
              }}
            >
              <Container maxWidth="xl">
                <div className=""></div>
              </Container>
            </div> */}
            <div className="">
            <Container maxWidth="xl">
            <Grid container spacing={2}>
            <Grid className="donation_grid" item xs={12} sm={12} md={6} lg={8}>
            <div
              className="donation_bg"
              // style={{
              //   backgroundImage: donation.banner_image
              //     ? `url(${donation.banner_image.base_url}${donation.banner_image.image_path})`
              //     : "",
              // }}
            >
              <img src={`${donation.banner_image.base_url}${donation.banner_image.image_path}`}/>
              {/* <Container maxWidth="xl">
                <div className=""></div>
              </Container> */}
            </div>
              </Grid>
              <Grid item xs={12} sm={12} md={0} lg={0}></Grid>
              </Grid>
              </Container>
            </div>
          </section>
          {/* <Swiper
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <section
                className="donation_bg"
                style={{
                  backgroundImage: donation.banner_image
                    ? `url(${donation.banner_image.base_url}${donation.banner_image.image_path})`
                    : "",
                }}
              >
                <Container>
                  <div className=""></div>
                </Container>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="donation_bg">
                <Container>
                  <div className=""></div>
                </Container>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="donation_bg">
                <Container>
                  <div className=""></div>
                </Container>
              </section>
            </SwiperSlide>
          </Swiper> */}
          <section className="details_donation details_donation_sm">
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <div className="details-page-1">
                  <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
                    <Foundation_construct
                      donation={donation}
                      totalDonation={totalDonation}
                      donationAmount={donationAmount}
                      setDonationAmount={setDonationAmount}
                      setCountersReset={setCountersReset}
                      setIsDonationEdited={setIsDonationEdited}
                      name="hi"
                      minimumDonation={minimumDonation}
                      setMinimum={setMinimum}
                      minimumAmount={minimumAmount}
                    />
                  </Grid>
                </div>
                <Grid item xs={12} sm={12} md={8} lg={7} xl={8}>
                  <div className="details_donation_scroller">
                    {/* <Products products={donation.products}/> */}
                    <div className="">
                      <div className="champaign-tab donation_champaign_tab">
                        <nav className="nav-1">
                          <div className="child-nav">
                            {donation.products.length > 0 && (
                              <Button
                                className={
                                  visibleSection === "section1" ? "active" : ""
                                }
                                onClick={() => handleNavClick(section1Ref)}
                              >
                                Sevas
                              </Button>
                            )}

                            <Button
                              className={
                                visibleSection === "section2" ? "active" : ""
                              }
                              onClick={() => handleNavClick(section2Ref)}
                            >
                              Story
                            </Button>
                            <Button
                              className={
                                visibleSection === "section3" ? "active" : ""
                              }
                              onClick={() => handleNavClick(section3Ref)}
                            >
                              Videos
                            </Button>
                            <Button
                              className={
                                visibleSection === "section4" ? "active" : ""
                              }
                              onClick={() => handleNavClick(section4Ref)}
                            >
                              Updates
                            </Button>
                            {/* <Button
                              className={
                                visibleSection === "section45"
                                  ? "active"
                                  : "d-none-sm"
                              }
                              onClick={() => handleNavClick(section45Ref)}
                            >
                              Recommended
                            </Button> */}
                          </div>
                        </nav>
                        <div
                          ref={section1Ref}
                          data-section="section1"
                          className="section1"
                        >
                          {/* <Typography variant="h2">Product Items</Typography> */}
                          <div className="details-page-item">
                            <Product_items
                              products={donation.products}
                              donationAmount={donationAmount}
                              isDonationEdited={isDonationEdited}
                              setIsDonationEdited={setIsDonationEdited}
                              updateDonationAmount={updateDonationAmount}
                              countersReset={countersReset}
                            />
                          </div>
                        </div>

                        <div
                          ref={section2Ref}
                          data-section="section2"
                          className="section3"
                        >
                          <Updates aboutseva={donation.about} />
                        </div>
                        <div
                          ref={section3Ref}
                          data-section="section3"
                          className="section2"
                        >
                          <Video sevavideo={donation.promotion} />
                        </div>
                        <div
                          ref={section4Ref}
                          data-section="section4"
                          className="section3"
                        >
                          {donation &&
                            (donation.donation_updates || !donation.youtube_url) &&
                            (donation.donation_updates.length === 0 && !donation.youtube_url) ? (
                            <div className="main_updates">
                              <div className="update-section">
                                <Typography variant="h4">
                                  There are no updates
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            donation && (
                              <div className="update-section">
                                <Typography variant="h4">Updates</Typography>
                                {/* <Typography
                                  dangerouslySetInnerHTML={{
                                    __html: update.description,
                                  }}
                                ></Typography> */}
                                <div className="donation-main donation_main_updates">
                                  {donation.donation_updates &&
                                    donation.donation_updates.map(
                                      (update, index) => (
                                        <div key={update.id}>
                                          <Typography className="updates_date">
                                            #{index + 1}:{" "}
                                            {formatDateToIndian(
                                              update.created_at
                                            )}
                                          </Typography>
                                          <Typography>
                                            {update.description}
                                          </Typography>
                                          {update.upload_type === "image" && (
                                            <Swiper
                                              spaceBetween={30}
                                              modules={[Autoplay, Pagination, Navigation]}
                                              loop={true}
                                              pagination={{ clickable: true }}
                                              autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                              }}
                                              
                                            >
                                              {update.update_imges &&
                                                update.update_imges.map(
                                                  (image) => (
                                                    <SwiperSlide key={image.id}>
                                                      <img
                                                        src={image.image_path}
                                                        alt={image.image_name}
                                                      />
                                                    </SwiperSlide>
                                                  )
                                                )}
                                            </Swiper>
                                          )}
                                          {update.upload_type === "video" &&
                                            update.update_imges &&
                                            update.update_imges.map((image) => (
                                              <ReactPlayer
                                                className="video_section-content"
                                                url={image.image_path}
                                                controls={true}
                                              />
                                            ))}
                                          {update.upload_type === "video" &&
                                            update.update_imges &&
                                            update.update_imges.map((image) => (
                                              <ReactPlayer
                                                className="video_section-content"
                                                url={image.image_path}
                                                controls={true}
                                              />
                                            ))}
                                        </div>
                                      )
                                    )}
                                 {donation.youtube_url ? (<div className="youtube_video">
                                <div className="youtube_video_content">
                                 
                                    <ReactPlayer
                                     className="video_section-content"
                                      url={donation.youtube_url}
                                      controls={true}
                                    />
                                 
                                </div>
                                </div> ) : null}
                                </div>
                                {/* <div className="donation-main donation-main_1">
                                  <Typography>
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book. It has
                                    survived not only five centuries, but also
                                    the leap into electronic typesetting,
                                    remaining essentially unchanged. It was
                                    popularised in the 1960s with the release of
                                    Letraset sheets containing Lorem Ipsum
                                    passages, and more recently with desktop
                                    publishing software like Aldus PageMaker
                                    including versions of Lorem Ipsum
                                  </Typography>
                                  <Swiper
                                    spaceBetween={30}
                                    // centeredSlides={true}
                                    autoplay={{
                                      delay: 2500,
                                    }}
                                    modules={[Autoplay]}
                                  >
                                    <SwiperSlide>
                                      <img src={donation_image} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <img src={donation_image} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <img src={donation_image} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <img src={donation_image} alt="" />
                                    </SwiperSlide>
                                  </Swiper>
                                </div> */}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
                  <div className="details-page-2">
                    <Foundation_construct
                      donation={donation}
                      totalDonation={totalDonation}
                      donationAmount={donationAmount}
                      setDonationAmount={setDonationAmount}
                      setCountersReset={setCountersReset}
                      setIsDonationEdited={setIsDonationEdited}
                      name="hi"
                      minimumDonation={minimumDonation}
                      setMinimum={setMinimum}
                      minimumAmount={minimumAmount}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
            {/* <Container>
              <div
                style={{ margin: "20px" }}
                ref={section45Ref}
                data-section="section45"
                className="section4 recommendedseva_4"
              >
                <Recommended_seva />
              </div>
            </Container> */}
            {noDonors > 0 && (
              <div className="donors_list">
                <Container maxWidth="xl">
                  <div className="donors_list_title">
                    <h2>
                      Donors <span>( {noDonors} ) </span>
                    </h2>
                    <div className="">
                      <Donors />
                    </div>
                  </div>
                </Container>
              </div>
            )}

            <div className="donations_count">
              <div className="donations">
                {currentDonation && (
                  <div className="donations_count_days">
                    <p>
                      ₹ {Number(currentDonation?.original_amount)} Donated By{" "}
                      {currentDonation?.is_anyomouns === false
                        ? `${personalDetails.firstName} ${personalDetails.lastName}`
                        : "Anonymous"}{" "}
                      {currentDonation?.timeAgo}{" "}
                    </p>
                  </div>
                )}
                <div className="donations_count_main">
                  {donation.donation_expired === false && (rounded_percentage >= 100 ? ( 
                    <Button className="target_reached_btn">
                    Target Reached
                    </Button>
                    ): (
                    <Button
                      onClick={() => {
                        // if (!isLoggedIn) {
                        //   setOpen(true);
                        // } else {
                        handlecheck();
                        // }
                      }}
                    >
                      I Donate ₹{" "}
                      {Intl.NumberFormat("en-IN", options).format(
                        donationAmount
                      )}
                      {/* <img src={rightarrow} /> */}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <a
            href="https://api.whatsapp.com/send?phone=919256585656"
            target="_blank"
            class="btn-whatsapp-pulse btn-whatsapp-pulse-border"
          >
            <img alt="whatsapp" src={whatsapp} />
          </a>
        </>
      ) : (
        <>
          {/* <div className="loader">
            <CircularProgress size={38} />
          </div> */}
        </>
      )}
    </>
  );
}
export default Details_donation;
