import React, { useState, useEffect } from "react";
import Special_Pujas_Donations from "../assets/images/icons/puja-donation.png";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import { Box, Button, Stack } from "@mui/material";
import right_arrow_btn from '../assets/images/icons/orange-right-arrow.svg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Scrollbar } from "swiper/modules";
import "../styles/components/sevacards.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link, useNavigate } from "react-router-dom";
import { noauthinstance } from "../utils/api";


function Sevacards() {
  const [pujas, setPujas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await noauthinstance.get("pujaAll/");
        setPujas(response.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const [isReadMore, setIsReadMore] = useState({});

  const toggleReadMore = (pujaId) => {
    setIsReadMore((prevState) => ({
      ...prevState,
      [pujaId]: !prevState[pujaId],
    }));
  };

  return (
    <div className="special-pujas-box">
      <Swiper
        modules={[Autoplay, Pagination, Scrollbar]}
        spaceBetween={50}
        slidesPerView={3}
        loop={true}
        pagination={{ clickable: true }} autoplay={{
          delay: 3000,
          // disableOnInteraction: true,
          // reverseDirection: false,
  
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          650: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {pujas.map((puja) => (
          <SwiperSlide style={{ height: "auto" }} key={puja.id}>
            <div
              className="pujas_box"
              onClick={() => navigate(`/pujas/${puja.slug}`)}
            >
              <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
                <Box>
                  <div className="puja_featured_image">
                    <img
                      src={`${puja.featured_image.base_url}${puja.featured_image.image_path}`}
                      alt=""
                    />
                  </div>
                  <h3>{puja.short_name}</h3>
                  <p>{puja.short_description.slice(0, 215)}</p>

                  <h4>Cause: {puja.location}</h4>
                  <h5>
                    Date:{" "}
                    {new Date(puja.start_date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </h5>
                </Box>
                <div className="book-now">
                  <Link to={`/pujas/${puja.slug}`}>
                    {puja.donation_expired === false ? (
                      <Button className="btn">Book Now</Button>
                    ) : (
                      <Button className="btn">View Updates</Button>
                    )}
                  </Link>
                </div>
              </Stack>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="view-all">
              <Link to="/exploreall?active_tab=puja">
                <Button>View All
                  <img src={right_arrow_btn}/>
                </Button>
              </Link>
            </div>
    </div>
  );
}
export default Sevacards;
