import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Container } from "@mui/material";
import SevaBookingSuccessfulImage from "../assets/images/check_out/mycart/seva-booking-successful-image.svg";
import "../styles/pages/home.css";
import "../styles/pages/payment_cancel.css";
import Typography from "@mui/material/Typography";
import Special_offer from "../components/Special_offer";
import SevaBookingFailedImage from '../assets/images/icons/transaction_failed.png'
import whatsapp from '../assets/images/icons/whatsapp.png'


function Cancelled() {
  const { slug } = useParams();
  console.log(slug);
  const amount = Number(localStorage.getItem("payment_amount"));
  return (
    <>
      <Header />

      <div className="main_payment_cancel">
        <Container>
          <div className="payment_cancel">
          <div className="Seva-Booking">
              <img
                src={SevaBookingFailedImage}
                alt="Seva Booking Successful"
              />
              <div className="Seva-Booking-text">
                <p>Amount = <span>₹ {amount}</span></p>
                <Typography variant="h5">Transaction Failed</Typography>
                <Typography variant="body1">
                Apologies for the inconvenience, but the transaction failed to process..
                </Typography>
              </div>
              {/* <Typography variant="h6">
                You will get a confirmation call or WhatsApp within 12 hrs of
                booking
              </Typography> */}
            </div>
          </div>
            {/* <section className="our-special-offerss">
            <div className="our-special-offers-bg">
              <Special_offer />
            </div>
          </section> */}
        </Container>
      </div>
        <Footer />
        <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
          <img alt="whatsapp" src={whatsapp}/>
        </a>
    </>
  );
}

export default Cancelled;
