import donate from "../assets/images/donate-now.png";
import { Button } from "@mui/material";
import "../styles/components/donation.css";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { noauthinstance } from "../utils/api";
import { Link } from "react-router-dom";

function Donation() {
  const [donation, SetDonation] = useState([]);
  async function getDonations() {
    try {
      const response = await noauthinstance.get("donation/featured/");

      if (response.status === 200) {
        console.log(response.data);
        SetDonation(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDonations();
  }, []);
  

  return (
    <>
      <Swiper
        loop={true}
        // direction={"vertical"}

        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {donation.map((donation, index) => (
          <SwiperSlide key={index}>
            <div className="main-box-content">
              <div className="small-support-box">
                <div className="small_support_large_box_image">
                  <img
                    src={`${donation.featured_image.base_url}${donation.featured_image.image_path}`}
                    alt="Donate"
                  />
                </div>
                <div className="small_support_large_box_content">
                  <h3>{donation.name}</h3>
                  <p>{donation.description}</p>
                  <div className="donate_now donate_now_lg">
                    <Link to={`/donation/${donation.donation.slug}`}>
                      <Button disableRipple disableElevation className="btn">
                        I Donate
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="donate_now donate_now_sm">
                <Link to={`/donation/${donation.donation.slug}`}>
                  <Button disableRipple disableElevation className="btn">
                    I Donate
                  </Button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
export default Donation;
