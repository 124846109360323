import React, { useEffect, useState } from "react";
import instance from "../utils/api";
import { MdFileDownload } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf";

// Set PDF.js worker path to load worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function GDownload() {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const response = await instance.get("/customer/my_docs");
        setDocuments(response.data);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    }

    fetchDocuments();
  }, []);

  const handleDownload = (imagePath) => {
    // Construct the full URL for downloading the document
    const downloadUrl = `http://43.205.34.80:91${imagePath}`;
    // Create an anchor element and initiate the download
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = imagePath.split("/").pop(); // Set filename to the last part of imagePath
    anchor.click();
  };

  const renderPDFThumbnail = (documentUrl) => (
    <Document file={`${documentUrl}`} onLoadSuccess={(pdf) => console.log(pdf)}>
      <Page pageNumber={1} width={100} />
    </Document>
  );

  return (
    <div className="gdownload">
      <h2>My Documents</h2>
      <div className="document-grid">
        {documents.map((doc) => (
          <div key={doc.id} className="document-item">
            <div>
              {doc.document.image_path.toLowerCase().endsWith('.pdf') ? (
                renderPDFThumbnail(`${doc.document.base_url}${doc.document.image_path}`)
              ) : (
                <img src={`${doc.document.base_url}${doc.document.image_path}`} alt="Document" width={100} />
              )}
            </div>
            <div className="gdown">
              <MdFileDownload onClick={() => handleDownload(doc.document.image_path)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GDownload;
