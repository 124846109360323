import React, { useEffect, useState } from "react";
import "../styles/pages/profile.css";
import edit from "../assets/images/profile/edit.svg";
import delete_img from '../assets/images/icons/delete.png'
import "../styles/components/profile_details.css";
import TextField from "@mui/material/TextField";
import { Grid, Button } from "@mui/material";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import saveprofile from "../assets/images/icons/save-profile.svg";
import addmore from "../assets/images/icons/add_more.svg";
import instance from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

function Karta_ancestor_details() {
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const [persons, setPersons] = useState([]);
  const [showForm, setShowForm] = useState(false); 
  const [relations, setRelations] = useState([]);
  const [rasis, setRasis] = useState([]);
  const [nakshatras, setNakshatras] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gothram: "",
    relation: "",
    rasi: "",
    nakshatram: "",
  });


  useEffect(() => {
    fetchPersonDetails();
    fetchRelations();
    fetchRasis();
    fetchNakshatras();
  }, []);
  const fetchPersonDetails = async () => {
    try {
      const response = await instance.get(
        "customer/family_detail?family_type=Kartha_Ancestor"
      );

      setPersons(response.data);
    } catch (error) {
      console.error("Error fetching person details:", error);
    }
  };
  const fetchRelations = async () => {
    try {
      const response = await instance.get("master/relations/");
      setRelations(response.data);
    } catch (error) {
      console.error("Error fetching relations:", error);
    }
  };

  const fetchRasis = async () => {
    try {
      const response = await instance.get("master/rasi/");
      setRasis(response.data);
    } catch (error) {
      console.error("Error fetching rasis:", error);
    }
  };

  const fetchNakshatras = async () => {
    try {
      const response = await instance.get("master/nakshatra");
      setNakshatras(response.data);
    } catch (error) {
      console.error("Error fetching nakshatras:", error);
    }
  };

  const handleAddFamilyMemberClick = () => {
    setShowForm(!showForm);
  };
  const handleSave = async () => {
    try {
      if (!formData.name) {
        toast.error("Name is a mandatory field");
        return;
      }
      let dataToSend = {
        name: formData.name,
        family_type: "Kartha_Ancestor",
        gothram: formData.gothram,
        relation: formData.relation, // Assuming the backend expects 'relation_id'
        rasi: formData.rasi, // Assuming the backend expects 'rasi_id'
        nakshatram: formData.nakshatram, // Assuming the backend expects 'nakshatram_id'
      };
  
      if (formData.dob) {
        // Formatting date only if it exists
        const formattedDate = dayjs(formData.dob).format("YYYY-MM-DD");
        dataToSend = { ...dataToSend, dob: formattedDate };
      }
      if (formData.id) {
        const response = await instance.patch(
          `customer/family_detail/${formData.id}`,
          dataToSend
        );
      } else {
        const response = await instance.post(
          "customer/family_detail",
          dataToSend
        );
      }
  
      fetchPersonDetails();
      resetFormData();
      setShowForm(false);
    } catch (error) {
      console.error("Error saving family member:", error);
    }
  };
  
  
  const resetFormData = () => {
    setFormData({
      name: "",
      dob: "",
      gothram: "",
      relation: "",
      rasi: "",
      nakshatram: "",
    });
  };
  const handleCancel = () => {
    resetFormData(); 
    setShowForm(false); 
  };
  const handleEdit = async (personId) => {
    const personToUpdate = persons.find((person) => person.id === personId);
    if (personToUpdate) {
      const dobDate = personToUpdate.dob ? dayjs(personToUpdate.dob) : null;
      setFormData({ ...personToUpdate, dob: dobDate }); 
      setShowForm(true); 
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        relation: personToUpdate.relation ? personToUpdate.relation.id : "", 
        rasi: personToUpdate.rasi ? personToUpdate.rasi.id : "", 
        nakshatram: personToUpdate.nakshatram ? personToUpdate.nakshatram.id : "", 
      }));
    }
  };
  
  const handledelete = async (personId) => {
    try {
        await instance.delete(`customer/family_detail/delete/${personId}`);
        
        console.log(`Person with ID ${personId} deleted successfully.`);
       toast.success("Person deleted successfully")
       fetchPersonDetails();
    } catch (error) {
        console.error(`Error deleting person with ID ${personId}:`, error);
       toast.error("Error deleting person")
    }
};
  return (
    <>
      <div className="">
        {/* <div className="add_more">
                <Button disableRipple disableElevation>Add<img src={addmore}/></Button>
                </div> */}
        <div className="add_member">
          <div className="">
            <p>Add Kartha Ancestor Members</p>
          </div>
          <div className="add_more">
            <Button
              disableRipple
              disableElevation
              onClick={handleAddFamilyMemberClick}
            >
              Add
              <img src={addmore} />
            </Button>
          </div>
        </div>
        {showForm && (
          <div className="userprofile_right_bg">
            <div className="userprofile_right_bg_content">
              <div className="personal_info">
                <h3>Personal Information</h3>
              </div>
              {/* <div className="edit_button">
                <Button disableRipple disableElevation>
                  Edit
                  <img src={edit} alt="Edit" />
                </Button>
              </div> */}
            </div>
            <div className="personal-info">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="outlined-basic"
                    label={
                        <>
                          Full Name <span style={{ color: 'red' }}>*</span>
                        </>
                      }
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="date_picker_new">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          
                          value={formData.dob ? dayjs(formData.dob) : null}
                          onChange={(value) =>
                            setFormData({ ...formData, dob: value })
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="">
                    <TextField
                      id="outlined-basic"
                      label="Gotram"
                      variant="outlined"
                      name="gothram"
                      value={formData.gothram}
                      onChange={handleChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Relation
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.relation}
                        onChange={handleChange}
                        name="relation"
                        label="relation"
                      >
                        {relations.map((relation) => (
                          <MenuItem key={relation.id} value={relation.id}>
                            {relation.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Nakshatram
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.nakshatram}
                        onChange={handleChange}
                        name="nakshatram"
                        label="nakshatram"
                      >
                        {nakshatras.map((nakshatra) => (
                          <MenuItem key={nakshatra.id} value={nakshatra.id}>
                            {nakshatra.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Rasi
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.rasi}
                        onChange={handleChange}
                        name="rasi"
                        label="rasi"
                      >
                        {rasis.map((rasi) => (
                          <MenuItem key={rasi.id} value={rasi.id}>
                            {rasi.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <div className="">
                    <TextField
                      id="outlined-basic"
                      label="Notes"
                      variant="outlined"
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                    />
                  </div>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="rest_save_button">
                  <Button
                      disableRipple
                      disableElevation
                      className="rest"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      disableRipple
                      disableElevation
                      className="save"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        <div className="family-scroll">
          {persons.map((person) => (
            <div className="save_info_details" key={person.id}>
              <div className="person_save_info">
                <img
                  className="save_profile_image"
                  src={saveprofile}
                  alt="Profile"
                />
                <h3>{person.name}</h3>
                <div className="edit-delete">
                <div className="edit_button">
                  <Button
                    disableRipple
                    disableElevation
                    onClick={() => handleEdit(person.id)}
                  >
                    Edit
                    <img src={edit} alt="Edit" />
                  </Button>
                </div>
                <div className="edit_button">
                  <Button
                    disableRipple
                    disableElevation
                    onClick={() => handledelete(person.id)}
                  >
                    Delete
                    <img src={delete_img} alt="Edit" />
                  </Button>
                </div>
                </div>
              </div>
              <div className="relation_rashi_details_main">
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Relation</h4>
                    <p>{person.relation?.name || "N/A"}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Rashi</h4>
                    <p>{person.rasi?.name || "N/A"}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Nakshatram</h4>
                    <p>{person.nakshatram?.name || "N/A"}</p>
                  </div>
                </div>
                <div className="relation_rashi_details relation_rashi_details_2">
                  <div className="relation_rashi_details_list">
                    <h4>Gotram</h4>
                    <p>{person.gothram || "N/A"}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Date of Birth</h4>
                    <p>{person.dob || "N/A"}</p>
                  </div>
                </div>
                {/* <div className="notes">
                       <h4>Notes</h4>
                       <p>Lorem Epson is a Dummy text to fill the Sentences Lorem Epson is a Dummy text to fill the Sentences</p>
                   </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default Karta_ancestor_details;
