import React, { useEffect, useState } from "react";
import "../styles/pages/profile.css";
import edit from "../assets/images/profile/edit.svg";
import delete_img from "../assets/images/icons/delete.png";
import TextField from "@mui/material/TextField";
import { Grid, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "../styles/components/profile_details.css";
import "../styles/components/order_transaction.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import addmore from "../assets/images/icons/add_more.svg";
import instance from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Address() {
  const [showForm, setShowForm] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [addressType, setAddressType] = useState("");
  const [error, setError] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const id = localStorage.getItem("vedauserid");

  const fetchAddresses = async () => {
    try {
      const response = await instance.get(`customer/address/${id}`);
      setAddresses(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    fetchAddresses();
  }, []);
  //   const fetchCountries = async () => {
  //     try {
  //       const response = await instance.get("master/countries/");
  //       setCountries(response.data.results);
  //     } catch (error) {
  //       console.error("Error fetching countries:", error);
  //     }
  //   };

  //   const fetchStates = async (countryId) => {
  //     try {
  //       const response = await instance.get(
  //         `master/states/?country=${countryId}`
  //       );
  //       setStates(response.data.results);
  //     } catch (error) {
  //       console.error("Error fetching states:", error);
  //     }
  //   };

  //   const fetchCities = async (stateId) => {
  //     try {
  //       const response = await instance.get(`master/cities/?state=${stateId}`);
  //       setCities(response.data.results);
  //     } catch (error) {
  //       console.error("Error fetching cities:", error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchCountries();
  //   }, []);

  //   const handleChangeCountry = (event) => {
  //     const countryId = event.target.value;
  //     setSelectedCountry(countryId);
  //     setSelectedState("");
  //     setSelectedCity("");
  //     if (countryId) {
  //       fetchStates(countryId);
  //     }
  //   };

  //   const handleChangeState = (event) => {
  //     const stateId = event.target.value;
  //     setSelectedState(stateId);
  //     setSelectedCity("");
  //     if (stateId) {
  //       fetchCities(stateId);
  //     }
  //   };

  //   const handleChangeCity = (event) => {
  //     setSelectedCity(event.target.value);
  //   };

  const handleAddAddressClick = () => {
    setShowForm(!showForm);
  };
  const handleEditAddress = async (addressId) => {
    const addressToEdit = addresses.find((address) => address.id === addressId);
    if (addressToEdit) {
      console.log(addressToEdit);
      setFullName(addressToEdit.full_name);
      setMobileNumber(addressToEdit.phone_no);
      setWhatsappNo(addressToEdit.whatsup_no);
      setEmail(addressToEdit.email);
      setAddressLine1(addressToEdit.address1);
      setAddressLine2(addressToEdit.address2);
      setPinCode(addressToEdit.pincode);
      setAddressType(addressToEdit.address_type);
      setSelectedCountry(addressToEdit.country);

      //   const stateExists = states.find(
      //     (state) => state.id === addressToEdit.state.id
      //   );
      //   if (!stateExists) {
      //     try {
      //       await fetchStates(addressToEdit.country.id);
      //     } catch (error) {
      //       console.error("Error fetching states:", error);
      //     }
      //   }

      //   const cityExists = cities.find(
      //     (city) => city.id === addressToEdit.city.id
      //   );
      //   if (!cityExists) {
      //     try {
      //       await fetchCities(addressToEdit.state.id);
      //     } catch (error) {
      //       console.error("Error fetching cities:", error);
      //     }
      //   }

      setSelectedState(addressToEdit.state);
      setSelectedCity(addressToEdit.city);

      setShowForm(true);
      setEditingAddressId(addressId);
    }
  };

  const handleSaveAddress = async () => {
    try {
      if (
        !fullName ||
        !mobileNumber ||
        !addressLine1 ||
        !pinCode ||
        !addressType
      ) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email && !emailPattern.test(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }

      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(mobileNumber)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      if (whatsappNo && !(phonePattern.test(whatsappNo))  ) {
        toast.error("Please enter a valid 10-digit WhatsApp number.");
        return;
      }

      const pincodePattern = /^[1-9][0-9]{5}$/;
      console.log(pincodePattern.test(pinCode));
      if (!pincodePattern.test(pinCode)) {
        toast.error("Please enter a valid pincode.");
        return;
      }

      const dataToSend = {
        full_name: fullName,
        phone_no: mobileNumber,
        whatsup_no: whatsappNo,
        email: email,
        address1: addressLine1,
        address2: addressLine2,
        pincode: pinCode,
        address_type: addressType,
        city: selectedCity,
        state: selectedState,
        country: selectedCountry,
      };

      let response;
      if (editingAddressId) {
        response = await instance.patch(
          `customer/address/${editingAddressId}`,
          dataToSend
        );
      } else {
        response = await instance.post("customer/address", dataToSend);
      }

      console.log("Address saved successfully:", response.data);
      toast.success("Address saved successfully.");
      setShowForm(false);
      fetchAddresses();
      clearForm();
      setError("");
    } catch (error) {
      console.error("Error saving/updating address:", error);
      toast.error("Error saving address. Please try again later.");
    }
  };

  const clearForm = () => {
    setFullName("");
    setMobileNumber("");
    setEmail("");
    setAddressLine1("");
    setAddressLine2("");
    setPinCode("");
    setWhatsappNo("");
    setAddressType("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
  };
  const handleDelete = async (addressId) => {
    try {
      await instance.delete(`customer/address/delete/${addressId}`);

      console.log(`Address with ID ${addressId} deleted successfully.`);
      toast.success("Address deleted successfully");
      fetchAddresses();
    } catch (error) {
      console.error(`Error deleting Address with ID ${addressId}:`, error);
      toast.error("Error deleting Address");
    }
  };
  return (
    <>
      <div className="">
        <div className="add_member">
          <div className="">
            <p>Add Address</p>
          </div>
          <div className="add_more">
            <Button
              disableRipple
              disableElevation
              onClick={handleAddAddressClick}
            >
              Add
              <img src={addmore} />
            </Button>
          </div>
        </div>
        {showForm && (
          <div className="userprofile_right_bg">
            <div className="userprofile_right_bg_content">
              <div className="personal_info">
                <h3>Personal Information</h3>
              </div>
              {/* <div className="edit_button">
                                <Button disableRipple disableElevation>Edit<img src={edit} /></Button>
                            </div> */}
            </div>
            <div className="personal-info profile-adress">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="fullName"
                    label="Full Name *"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="mobileNumber"
                    label="Mobile Number *"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="whatsappNo"
                    label="Alternate No"
                    value={whatsappNo}
                    onChange={(e) => setWhatsappNo(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine1"
                    label="Address Line 1 *"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                  <TextField
                    id="addressLine2"
                    label="Address Line 2"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="country-label">Country *</InputLabel>
                                        <Select
                                            labelId="country-label"
                                            id="country-select"
                                            value={selectedCountry}
                                            onChange={handleChangeCountry}
                                            variant="outlined"
                                            label="Country *"
                                        >
                                            
                                            {countries.map((country) => (
                                                <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                            ))}
                                        </Select> */}
                    <TextField
                      id="country"
                      label="Country *"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="state-label">State *</InputLabel>
                    <Select
                      labelId="state-label"
                      id="state-select"
                      value={selectedState}
                      onChange={handleChangeState}
                      variant="outlined"
                      label="State *"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <TextField
                      id="state"
                      label="State *"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="city-label">City *</InputLabel>
                    <Select
                      labelId="city-label"
                      id="city-select"
                      value={selectedCity}
                      onChange={handleChangeCity}
                      variant="outlined"
                      label="City *"
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <TextField
                      id="city"
                      label="City *"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="pinCode"
                    label="Pin Code *"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel id="addressType-label">
                      Address Type *
                    </InputLabel>
                    <Select
                      labelId="addressType-label"
                      id="addressType-select"
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      variant="outlined"
                      label="Address Type *"
                    >
                      <MenuItem value="">Select Address Type</MenuItem>
                      <MenuItem value="Home">Home</MenuItem>
                      <MenuItem value="Work">Work</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="rest_save_button">
                    <Button
                      className="rest"
                      disableRipple
                      disableElevation
                      onClick={() => {
                        setShowForm(false);
                        clearForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="save"
                      disableRipple
                      disableElevation
                      onClick={() => handleSaveAddress()}
                    >
                      Save Address
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        <div className="family-scroll">
          {addresses.map((address) => (
            <div className="save_info_details" key={address.id}>
              <div className="person_save_info">
                <h3>{address.full_name}</h3>
                <div className="edit-delete">
                  <div className="edit_button">
                    <Button
                      disableRipple
                      disableElevation
                      onClick={() => handleEditAddress(address.id)}
                    >
                      Edit
                      <img src={edit} alt="Edit" />
                    </Button>
                  </div>
                  <div className="edit_button">
                    <Button
                      disableRipple
                      disableElevation
                      onClick={() => handleDelete(address.id)}
                    >
                      Delete
                      <img src={delete_img} alt="Edit" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="relation_rashi_details_main">
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Full Name</h4>
                    <p>{address.full_name}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Mobile Number</h4>
                    <p>{address.phone_no}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Email</h4>
                    <p>{address.email || "N/A"}</p>
                  </div>
                </div>
                <div className="relation_rashi_details">
                  {/* Display address details */}
                  <div className="relation_rashi_details_list">
                    <h4>City</h4>
                    <p>{address.city}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>State</h4>
                    <p>{address.state}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Country</h4>
                    <p>{address.country}</p>
                  </div>
                </div>
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Address Line 1</h4>
                    <p>{address.address1}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Address Line 2</h4>
                    <p>{address.address2 || "N/A"}</p>
                  </div>
                </div>
                <div className="relation_rashi_details">
                  <div className="relation_rashi_details_list">
                    <h4>Pincode</h4>
                    <p>{address.pincode}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>WhatsApp Number</h4>
                    <p>{address.whatsup_no || "N/A"}</p>
                  </div>
                  <div className="relation_rashi_details_list">
                    <h4>Address Type</h4>
                    <p>{address.address_type}</p>
                  </div>
                </div>
                {/* Add more address details here */}
              </div>
              {/* Display more address details here */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Address;
