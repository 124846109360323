import React, { useState } from "react";
import '../styles/components/log_in.css';
import info from '../assets/images/icons/info.svg'
import { Button,Typography,CircularProgress} from "@mui/material";
import Back_button from '../assets/images/login-pop-up/orange-left-arrow.svg';
import Seva_Booking_seccessfull from '../assets/images/check_out/mycart/seva-booking-successful-image.svg';
import EastIcon from '@mui/icons-material/East';
import axios from 'axios';
import { noauthinstance } from "../utils/api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Log_in({ setOpen,phone,setPhone }){
    const [loading, setLoading] = useState(false);
    const handleLogin = async () => {
        try {
            console.log(phone);
            const isPhoneNumberValid = /^\d{10}$/.test(phone);
            console.log(isPhoneNumberValid)
            if(!isPhoneNumberValid){
                toast.error("Please enter a valid 10 digit number.");
                return
            }

            setLoading(true);
            const payload = {
                user: {
                    username: phone,
                    password: phone
                }
            };
            const response = await noauthinstance.post('customer/register/login', payload);
            setOpen("otp-page", { username: phone,setOpen:setOpen });

            console.log(response.data);
        } catch (error) {
            console.error('Login failed:', error);
        } finally {
            setLoading(false);
        }
    };
    
   
    return(
        
       <div className="login_main_popup">
        <div className="log_in_box_content">
            <div className="welcome_content">
                <h2>Welcome back!</h2>
                <p>Start your Journey!</p>
            <div className="mobile_no-content">
                <label For="">Mobile No</label>
                <div className="mobile_number">
                <input className="input_label" type="text" name="" id="" placeholder="+91"/>
                <input type="number" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <span>
                    <img src={info} alt=""/>
                    Enter 10 digit number to login your account
                </span>
            </div>
            <div className="login_btn">
                        <Button onClick={handleLogin} disableRipple disableElevation>
                            {loading ? <CircularProgress size={24} /> : 'Send OTP'}
                        </Button>
                    </div>

            </div>
        </div>
        {/* <div className="Seva-Booking">
              <img src={Seva_Booking_seccessfull} alt="Seva-Booking-seccessfull"/>
              <div className="Seva-Booking-text">
                  <Typography variant="h5">Seva Booking Successful</Typography>
                  <Typography variant="body1">We have successfully received your details</Typography>
              </div>
              <Typography variant="h6">You will get a confirmation call or WhatsApp within 12 hrs of booking</Typography>
              <Button>Back to Home<EastIcon/></Button>
        </div> */}
       </div>
    )
}
export default Log_in;