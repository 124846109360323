import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from '../src/pages/Home';
import Explore_puja from './pages/Explore_puja';
import Check_out from './pages/Check_out';
import Details_puja from './pages/Details_puja';
import Profile from './pages/Profile';
import Details_donation from './pages/Details_donation';
import Privacy_policy from './pages/Privacy_policy';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppProvider } from './components/Auth';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
 
     <BrowserRouter>
      <App/>
    </BrowserRouter>
  
  </AppProvider>
);

