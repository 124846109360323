import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import "../styles/pages/check_out.css";
import { Button, Container, TextField } from "@mui/material";
import SevaBookingSuccessfulImage from "../assets/images/check_out/mycart/seva-booking-successful-image.svg";
import "../styles/pages/home.css";
import "../styles/pages/payment_cancel.css";
import { Typography, Grid } from "@mui/material";
import Special_offer from "../components/Special_offer";
import { useAppContext } from "../components/Auth";
import { useLocation } from "react-router-dom";
import fav_icon from "../assets/images/icons/apple-touch-icon.png";
import download_doc from "../assets/images/icons/download.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import thanks_for_submitting from "../assets/images/icons/thanks-for-submitting.svg";
import Thankyou_card from "../components/Thankyou_card";
import thank_you_logo from "../assets/images/icons/thank-you-logo.svg";
import whatsapp from "../assets/images/icons/whatsapp.png";
import GTM from "../utils/gtm";
import { noauthinstance } from "../utils/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ThankYou() {
  const thankYou = localStorage.getItem("Thank_You") === "true";
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();

  console.log(typeof thankYou);
  const [secondsLeft, setSecondsLeft] = useState(10);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSecondsLeft((prev) => prev - 1);
  //   }, 1000000000000000);

  //   const timeout = setTimeout(() => {
  //     navigate("/");
  //     localStorage.removeItem("Thank_You");
  //   }, 10000);

  //   return () => {
  //     clearInterval(interval);
  //     clearTimeout(timeout);
  //   };
  // }, [navigate]);

  useEffect(() => {
    if (thankYou !== true || thankYou === null) {
      console.log("thankyou redirect", thankYou);
      navigate("/");
    }
  }, []);

  // if (thankYou !== true || thankYou === null) {
  //   console.log("redirect from thankyou", thankYou);
  //   navigate("/");
  // }

  const transactionId = localStorage.getItem("transactionId");
  const page = location.state?.page ? location.state.page : null;
  const amount = localStorage.getItem("payment_amount");

  useEffect(() => {
    // Function to make the API call
    const fetchData = async () => {
      try {

        // const response = await fetch(
        //   "https://backend.vedasankalpa.com/v1/customer/orderCheckAPIView"
        // ); // Replace with your API URL
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        // const result = await response.json();

        const response2 = await noauthinstance.get(
          `/customer/order_data/${transactionId}/`
        );
        console.log(response2.data);
        setData(response2.data);
        // console.log(result);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    };

    // Make the API call when the component mounts
    fetchData();
  }, []);

  useEffect(() => {
    // Assuming gtag is already available globally
    window.gtag("event", "conversion", {
      send_to: "AW-16473080225/lUh1CKyowqcZEKGD_a49",
      value: amount,
      currency: "INR",
      transaction_id: transactionId,
      event_callback: () => {
        console.log("Conversion event tracked successfully!");
      },
    });
  }, [transactionId]);

  console.log(thankYou);

  useEffect(() => {
    if (transactionId && amount) {
      const userName = localStorage.getItem("user_name");
      const slug = localStorage.getItem("slug");
      const gtm = new GTM();
      gtm.trackThankYou({
        transactionId,
        currency: "INR",
        amount,
      });
      gtm.trackPaymentSuccess({
        transactionId,
        currency: "INR",
        amount,
        userName,
        slug,
      });
    }
  }, [transactionId, amount]);

  // useEffect(() => {
  //   const handleBackButton = (event) => {
  //     event.preventDefault();
  //     window.location.replace(`/ThankYou/${donate_id}`);
  //   };

  //   window.addEventListener("popstate", handleBackButton);

  //   return () => {
  //     window.removeEventListener("popstate", handleBackButton);
  //   };
  // }, []);

  const [expanded, setExpanded] = useState(true);

  const handleExpansion = () => {
    setExpanded(!expanded);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header />

      <div className="main_payment_cancel">
        <Container>
          <div className="app_new_main_payment">
            <div className="thankyou_card">
              <Thankyou_card />
            </div>
            {/* <div className="eight_g_expanded">
              <Accordion className="personal-info" expanded={expanded}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon onClick={() => handleExpansion()} />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="personal_info_check_out">
                    Claim 80G Tax Benefit
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="personal_information_form">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="outlined-basic"
                          label="Email ID *"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="outlined-basic"
                          label="Name As per PAN *"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="outlined-basic"
                          label="PAN Number"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="outlined-basic"
                          label="Address *"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <div className="eight_g_submit">
                      <React.Fragment>
                        <Button onClick={handleClickOpen}>Submit</Button>
                        <BootstrapDialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                          className="eighty_g_modal"
                        >
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <DialogContent dividers>
                            <div className="submitting_details">
                              <div className="submitting_details_content">
                                <img
                                  className="thank_you_logo"
                                  src={thank_you_logo}
                                />
                                <p>Thank you for submitting your details</p>
                                <img src={thanks_for_submitting} />
                              </div>
                              <div className="tax_receipt_title">
                                <h4>
                                  Your 80G tax receipt will be e-mailed within
                                  24hrs
                                </h4>
                              </div>
                              <div className="tax_receipt_mail">
                                <p>
                                  We're making things easier for you! Instead of
                                  receiving separate 80G certificates for each
                                  donation, you'll receive a consolidated 80G
                                  certificate via email covering all your
                                  donations made through our platform in the
                                  financial year.
                                </p>
                                <p>
                                  The corresponding 10BE form that proves your
                                  eligibility for the tax deduction will also be
                                  consolidated. This way, you won't have to
                                  worry about multiple downloads and can save
                                  time. If you have any questions or need help,
                                  please feel free to start a chat with us.
                                </p>
                              </div>
                            </div>
                          </DialogContent>
                        </BootstrapDialog>
                      </React.Fragment>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div> */}
          </div>
        </Container>
        {/* <div className="main_new_payment">
            <div className="new-payment">
              <div className="share_achievement">
                <ul>
                  <li>Share the Achievement</li>
                  <li>
                    <Link to="">
                      <img src={download_doc} />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="new-payment-content">
                <h3>CERTIFICATE</h3>
                <p>TAKE YOUR STEP TO PROTECT DHARMA</p>
              </div>
              <div className="new-payment-content_2">
                <h4>PROUDLY PRESENTED TO</h4>
                <h2>Rajasekar reddy</h2>
              </div>
              <div className="new-payment-content_3">
                <h3>CAMPAIGN NAME</h3>
                <h4>XXXXXXXXX</h4>
              </div>
              <div className="new-payment-content_4">
                <img src={fav_icon} />
                <h4>www.vedasankalpa.com</h4>
              </div>
            </div>
            <div className="get-your-tx-certificate">
              <h4>Get Your tax Certificate</h4>
              <p>Add your PAN with 3 days to claim tax exemption</p>
              <div className="get-your-tx-certificate_pan">
                <label className="get-your-tx-certificate_pan_label">PAN</label>
                <TextField id="outlined-basic" label="PAN" variant="outlined" />
                <div className="continue_certificate">
                  <Button className="continue">Continue</Button>
                  <Button className="certificate">
                    I don't want a tax certificate
                  </Button>
                </div>
              </div>
            </div>
          </div> */}
        {/* <div className="eight_g_expanded">
            <Accordion className="personal-info" expanded={expanded}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon onClick={() => handleExpansion()} />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="personal_info_check_out">
                  Claim 80G Tax Benefit
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="personal_information_form">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="Email ID *"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="Name As per PAN *"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="PAN Number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="Address *"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <div className="eight_g_submit">
                    <React.Fragment>
                      <Button onClick={handleClickOpen}>
                        Submit
                      </Button>
                      <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        className="eighty_g_modal"
                      >
                       
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                          <div className="submitting_details">
                            <div className="submitting_details_content">
                              <p>Thank you for submitting your details</p>
                              <img src={postbox_mail}/>
                            </div>
                            <div className="tax_receipt_mail">
                                <h4>Your 80G tax receipt will be e-mailed within 24hrs</h4>
                                <p>We're making things easier for you! Instead of receiving separate 80G certificates for each donation, you'll receive a consolidated 80G certificate via email covering all your donations made through our platform in the financial year.</p>
                                <p>The corresponding 10BE form that proves your eligibility for the tax deduction will also be consolidated. This way, you won't have to worry about multiple downloads and can save time. If you have any questions or need help, please feel free to start a chat with us.</p>
                            </div>
                          </div>
                        </DialogContent>
                      </BootstrapDialog>
                    </React.Fragment>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div> */}
        {/* 
        <div className="payment_cancel">
          <div className="Seva-Booking">
            <img
              src={SevaBookingSuccessfulImage}
              alt="Seva Booking Successful"
            />
            <div className="Seva-Booking-text">
              <p>
                <span>₹ {amount}</span>
              </p>
              {page === "donation" && (
                <Typography variant="h5">Donated Successfully</Typography>
              )}
              {page === "seva" && (
                <Typography variant="h5">Seva Booking Successful</Typography>
              )}
              <Typography variant="body1">
                We have successfully received your details
              </Typography>
            </div>
            <Typography variant="h6">
              You will get a confirmation call or WhatsApp within 12 hrs of
              booking
            </Typography>
            <Typography variant="h6">
              Redirecting to home in {secondsLeft} seconds...
            </Typography>
          </div>
        </div> */}
        <Container>
          <section className="our-special-offerss">
            <div className="our-special-offers-bg">
              <Special_offer />
            </div>
          </section>
        </Container>
      </div>

      <Footer />
      <a
        href="https://api.whatsapp.com/send?phone=919256585656"
        target="_blank"
        class="btn-whatsapp-pulse btn-whatsapp-pulse-border"
      >
        <img alt="whatsapp" src={whatsapp} />
      </a>
    </>
  );
}

export default ThankYou;
