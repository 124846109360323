import React, { useEffect, useState } from "react";
import "../styles/pages/check_out.css";
import "../styles/pages/details_donation.css";
import Header from "../layouts/Header"; 
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Button,
  Switch,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Tooltip,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from "@mui/material";
import { Container } from "@mui/material";
import mycard from "../assets/images/check_out/mycart/card.svg";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Ganga_Jaal from "../assets/images/check_out/mycart/image-01.png";
import Mala from "../assets/images/check_out/mycart/image-02.png";
import Locket from "../assets/images/check_out/mycart/image-03.png";
import left_sm_arrow from "../assets/images/check_out/mycart/left-sm-arrow.svg";
import right_symbol from "../assets/images/check_out/mycart/right-symbol.svg";
import right_sm_arrow from "../assets/images/check_out/mycart/right-sm-arrow.svg";
import Footer from "../layouts/Footer";
import Checkbox from "@mui/material/Checkbox";
import edit from "../assets/images/profile/edit.svg";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import instance, { noauthinstance } from "../utils/api";
import { useAppContext } from "../components/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DonationSuccessfull from "../components/DonationSuccessfull";
import logoIcon from "../assets/images/logo/icon.png";
import { CloseOutlined } from "@mui/icons-material";
import ThankYou from "./ThankYou";
import { jwtDecode } from "jwt-decode";
import whatsapp from "../assets/images/icons/whatsapp.png";
import axios from "axios";
import GTM from "../utils/gtm";

function Donation_checkout() {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailprofile, setEmailprofile] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [panNo, setPanNo] = useState("");
  const [isIndian, setIsIndian] = useState("indian");
  const { setCustomerName, setCustomerPhone, setThankYou, thankYou, setIsLoggedIn } = useAppContext();
  const vedatoken = localStorage.getItem("vedatoken");
  const id = localStorage.getItem("vedauserid");
  const [activeStep, setActiveStep] = useState(0);
  const [counter, setCounter] = useState(0);
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);

  const [pujaData, setPujaData] = useState([]);
  const [familyPersons, setFamilyPersons] = useState([]);
  const [minimumDonation, setMinimum] = useState(false);
  const [error, setError] = useState({});
  const handleClose = () => {
    setOpen(false);
    clearForm();
  };
  const [open, setOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gothram: "",
    relation: "",
    rasi: "",
    nakshatram: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [tokenExpire, setTokenExpire] = useState(false);
  const { selectedProducts, setSelectedProducts, donationAmount, setDonationAmount } = useAppContext();
  const amount = localStorage.getItem("donationAmount");

  const navigate = useNavigate();
  useEffect(() => {
    fetchProfileData();
    fetchLocation();
  }, []);
  
  const fetchLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const city = response.data.city || "";
      const state = response.data.region || "";
      setAddress(`${city}, ${state}`); // Combine city and state
    } catch (error) {
      console.error("Error fetching IP location:", error);
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await instance.get(`customer/${id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const profileData = response.data;
      setFirstName(profileData.user.first_name || "");
      setLastName(profileData.user.last_name || "");
      setEmailprofile(profileData.user.email || "");
      setMobileNo(profileData.user.username || "");
      setCustomerName(`${profileData.user.first_name} ${profileData.user.last_name}`);
      setCustomerPhone(profileData.user.username);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const steps = ["Step 1"];

  const handleStepClick = (step) => () => {
    setActiveStep(step);
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const incrementCounter = () => {
    setCounter(counter + 1);
  };

  const decrementCounter = () => {
    if (counter !== 0) {
      setCounter(counter - 1);
    }
    setCounter(counter - 1);
  };

  const incrementCounter1 = () => {
    setCounter1(counter1 + 1);
  };

  const decrementCounter1 = () => {
    if (counter1 !== 0) {
      setCounter1(counter1 - 1);
    }
    setCounter1(counter1 - 1);
  };

  const incrementCounter2 = () => {
    setCounter2(counter2 + 1);
  };

  const decrementCounter2 = () => {
    if (counter2 !== 0) {
      setCounter2(counter2 - 1);
    }
    setCounter2(counter2 - 1);
  };
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  const { sevaprice_id } = useParams();
  const { donate_id } = useParams();
  const { slug } = useParams();
  const handleRemoveClick = () => {
    navigate("/");
  };

  if (amount === null || amount === undefined || amount === "undefined" || amount === "" || amount == 0) {
    setMinimum(true);
    navigate("/");
  }
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [sevafor, setSevaFor] = useState();
  const [donation_id, setDonationId] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [expire, setExpire] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await noauthinstance.get(`donation/${slug}`);
        if (response.data.donation_expired === true) {
          navigate("/");
        }
        setSelectedDonation(response.data);
        setDonationId(response.data.row_pre_id);
        setShowAddress(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const fetchExpire = async () => {
    try {
      const response = await noauthinstance.get(`donation/${slug}`);
      if (response.data.donation_expired === true) {
        setExpire(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [dakshanaOptions, setDakshanaOptions] = useState([]);
  const [selectedDakshana, setSelectedDakshana] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [deductTip, setDeductTip] = useState(false);
  const [showDakshanaOptions, setShowDakshanaOptions] = useState(true);

  const [deductAmount, setDeductAmount] = useState(0);

  useEffect(() => {
    const fetchDakshina = async () => {
      try {
        const response = await noauthinstance.get("donation/tips/");
        const data = response.data;
        setDakshanaOptions(data);

        const defaultOption = data.find((option) => option.is_default);
        if (defaultOption) {
          setSelectedDakshana(defaultOption.value);

          if (defaultOption.value === "other") {
            setSelectedAmount(customAmount);
          } else {
            const percentage = parseFloat(defaultOption.value) / 100;
            const calculatedAmount = amount * percentage;
            setSelectedAmount(calculatedAmount.toFixed(2));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDakshina();
  }, [amount]);

  const handleChangeDaskshana = (event) => {
    const selectedValue = event.target.value;
    setSelectedDakshana(selectedValue);

    if (selectedValue === "other") {
      const defaultOption = dakshanaOptions.find((option) => option.is_default);
      if (defaultOption) {
        const percentage = parseFloat(defaultOption.value) / 100;
        const calculatedAmount = amount ? amount * percentage : 0;
        setSelectedAmount(calculatedAmount.toFixed(2));
        setCustomAmount(calculatedAmount.toFixed(2));
      }
    } else {
      if (showDakshanaOptions) {
        const percentage = parseFloat(selectedValue) / 100;
        const calculatedAmount = amount ? amount * percentage : 0;
        setSelectedAmount(calculatedAmount.toFixed(2));
      } else {
        setSelectedAmount(0);
      }
    }
  };

  const handleDeductTipChange = (isChecked) => {
    if (isChecked) {
      const deductedValue = parseFloat(amount) * 0.07;
      setDeductAmount(deductedValue.toFixed(2));
      const newDonationAmount = parseFloat(amount) - deductedValue;
      setDonationAmount(newDonationAmount.toFixed(2));
      setDeductTip(isChecked);
      setShowDakshanaOptions(false);
    } else {
      const newDonationAmount = parseFloat(donationAmount) + parseFloat(deductAmount);
      setDonationAmount(newDonationAmount.toFixed(2));
      setDeductAmount(0);
      setDeductTip(isChecked);
      setCustomAmount("");
      setShowDakshanaOptions(false);
    }
  };

  const handleCustomAmountChange = (event) => {
    const customAmountValue = event.target.value;

    if (customAmountValue < 0) {
      setCustomAmount("");
      return;
    }

    if (customAmountValue === "0") {
      const newDonationAmount = amount * 0.93;
      setDonationAmount(newDonationAmount.toFixed(2));
      setDeductAmount((amount - newDonationAmount).toFixed(2));
      setSelectedAmount(0);
      setCustomAmount("");
      setDeductTip(true);
    } else {
      setCustomAmount(customAmountValue);
      setSelectedAmount(customAmountValue);
      setShowDakshanaOptions(true);
      setDeductTip(false);
      setDeductAmount(0);
    }
  };

  useEffect(() => {
    if (!showDakshanaOptions && !deductTip) {
      setSelectedAmount(0);
    } else if (deductTip) {
      setSelectedAmount(0);
      setShowDakshanaOptions(false);
    } else {
      setSelectedAmount(selectedDakshana === "other" ? customAmount : selectedAmount);
    }
  }, [showDakshanaOptions, deductTip, selectedDakshana, customAmount]);

  const tipAmount = (() => {
    if (!deductTip && !showDakshanaOptions) {
      return deductAmount;
    } else if (!deductTip) {
      return selectedAmount === "" ? 0 : selectedAmount;
    } else if (deductTip && !showDakshanaOptions) {
      return deductAmount;
    } else {
      return selectedAmount || 0;
    }
  })();

  const handleNextClick = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleprevClick = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [addressType, setAddressType] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [editingAddressId, setEditingAddressId] = useState(null);

  const [showModalAddress, setShowModalAddress] = useState(false);
  const [modalMode1, setModalMode1] = useState("add");
  const handleModalOutsideClick = (event) => {
    if (event.target.className === "modal1") {
      setShowModalAddress(false);
    }
  };
  const handleModal1OutsideClick = (event) => {
    if (event.target.className === "modal") {
      setShowModal(false);
    }
  };
  const handleAddAddress = () => {
    setOpen(true);
    setModalMode1("add");
  };
  const fetchAddresses = async () => {
    try {
      const response = await instance.get("customer/address");
      setAddresses(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    fetchAddresses();
  }, []);

  const [anonymous, setAnonymous] = useState(false);
  const [updates, setUpdates] = useState(true);

  const handleAnonymousChange = (event) => {
    setAnonymous(event.target.checked);
  };

  const handleUpdatesChange = (event) => {
    setUpdates(event.target.checked);
  };

  const handleEditAddress = async (addressId) => {
    const addressToEdit = addresses.find((address) => address.id === addressId);
    if (addressToEdit) {
      setOpen(true);
      setModalMode1("edit");

      setFullName(addressToEdit.full_name);
      setMobileNumber(addressToEdit.phone_no);
      setWhatsappNo(addressToEdit.whatsup_no);
      setEmail(addressToEdit.email);
      setAddressLine1(addressToEdit.address1);
      setAddressLine2(addressToEdit.address2);
      setPinCode(addressToEdit.pincode);
      setAddressType(addressToEdit.address_type);
      setSelectedCountry(addressToEdit.country);
      setSelectedState(addressToEdit.state);
      setSelectedCity(addressToEdit.city);

      setShowModalAddress(true);
      setEditingAddressId(addressId);
    }
  };

  const handleSaveAddress = async () => {
    try {
      if (!fullName || !mobileNumber || !addressLine1 || !pinCode || !whatsappNo || !addressType) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email && !emailPattern.test(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }

      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(mobileNumber)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      if (whatsappNo) {
        if (!phonePattern.test(whatsappNo)) {
          toast.error("Please enter a valid 10-digit WhatsApp number.");
          return;
        }
      }

      const dataToSend = {
        full_name: fullName,
        phone_no: mobileNumber,
        whatsup_no: whatsappNo,
        email: email,
        address1: addressLine1,
        address2: addressLine2,
        pincode: pinCode,
        address_type: addressType,
        city: selectedCity,
        state: selectedState,
        country: selectedCountry,
      };

      toast.success("Address saved successfully.");
      setShowModalAddress(false);
      handleClose();
      fetchAddresses();
      clearForm();
      setError("");
    } catch (error) {
      toast.error("Error saving address. Please try again later.");
    }
  };

  const clearForm = () => {
    setFullName("");
    setMobileNumber("");
    setEmail("");
    setAddressLine1("");
    setAddressLine2("");
    setPinCode("");
    setWhatsappNo("");
    setAddressType("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setShowModalAddress(false);
  };
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const handleFamilyMemberSelection = (e, person) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedFamilyMembers([...selectedFamilyMembers, person]);
    } else {
      setSelectedFamilyMembers(selectedFamilyMembers.filter((p) => p.id !== person.id));
    }
  };

  const handleAddressSelection = (addressId) => {
    setSelectedAddressId(selectedAddressId === addressId ? null : addressId);
  };

  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const sendPaymentStatusToServer = async (data, response, status) => {
    try {
      setProceedPay(false);
      if (status == "Success") {
        await noauthinstance.put(`/customer/order/${data.data.id}/update_status/`, {
          payment_status: status,
          transaction_id: response.razorpay_payment_id,
          transaction_response: JSON.stringify(data.data.razorpay_order),
        });
        let total = (parseFloat(deductTip ? amount - deductAmount : amount) + parseFloat(tipAmount)).toFixed(2);

        localStorage.setItem("payment_amount", total);
        localStorage.removeItem("donationAmount");
        localStorage.setItem("user_name", firstName + " " + lastName);
        localStorage.setItem("organisation_name", selectedDonation.temple.name);

        setThankYou(true);
        setSelectedProducts("");
        setDonationAmount("");
        setSelectedDonation(null);

        navigate(`/Thankyou`, {
          state: {
            transaction_id: response.razorpay_payment_id,
            page: "donation",
          },
        });
        setCheckoutSuccess(true);
        setShowModal1(true);
      } else if (status == "Cancelled") {
        await noauthinstance.put(`/customer/order/${data.data.id}/update_status/`, {
          payment_status: status,
          transaction_id: data.data.invoice_id,
          transaction_response: JSON.stringify(data.data.razorpay_order),
        });
      } else if (response.error.reason == "payment_failed") {
        await noauthinstance.put(`/customer/order/${data.data.id}/update_status/`, {
          payment_status: status,
          transaction_id: response.error.metadata.payment_id,
          transaction_response: JSON.stringify(data.data.razorpay_order),
        });
      }
    } catch (error) {
      console.error("Error sending payment status to server", error);
    }
  };
  const handleIndianChange = (event) => {
    setIsIndian(event.target.value === "indian");
  };
  const handleindiaClick = () => {
    if (isIndian === null) {
      return;
    }
  };

  const [proceedPay, setProceedPay] = useState(false);
  const updateEmailProfile = async (email) => {
    setEmailprofile(email);
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError((prev) => {
        return { ...prev, email: true };
      });
      setProceedPay(true);
      return false;
    } else {
      setProceedPay(false);
      setError((prev) => {
        return { ...prev, email: false };
      });
    }
  };

  const updateName = async (name, type) => {
    if (type === "first") setFirstName(name);
    if (type === "last") setLastName(name);
    const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
    if (!nameRegex.test(name)) {
      setError((prev) => {
        return { ...prev, [type]: true };
      });
      setProceedPay(true);
      return false;
    } else {
      setError((prev) => {
        return { ...prev, [type]: false };
      });
      setProceedPay(false);
    }
  };

  const updateMobile = async (number) => {
    setMobileNo(number);
    const nameRegex = /^\d{10}$/;
    if (!nameRegex.test(number)) {
      setError((prev) => {
        return { ...prev, mobile: true };
      });
      setProceedPay(true);
      return false;
    } else {
      setError((prev) => {
        return { ...prev, mobile: false };
      });
      setProceedPay(false);
    }
  };

  const handleCheckout = async () => {
    try {
      setProceedPay(true);
      fetchExpire();

      if (expire === true) {
        toast.error("Donation has expired. Please select another donation.");
        navigate("/");
        return;
      }

      let updatedProfileData = {
        user: {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: emailprofile.trim(),
        },
      };

      if (emailprofile) {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        if (!emailRegex.test(emailprofile)) {
          toast.error("Please enter a valid email address");
          return false;
        }
      }

      if (firstName && lastName) {
        const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
        if (!firstName.match(nameRegex) || !lastName.match(nameRegex)) {
          toast.error("Name should contain only alphabets");
          return false;
        }
      }

      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(mobileNo)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      const invalidStartDigitsPattern = /^[0-5]/;
      if (invalidStartDigitsPattern.test(mobileNo)) {
        toast.error("Please enter a valid mobile number.");
        return;
      }

      const requestData = {
        donation: Number(donate_id),
        donation_amount: Number(deductTip ? (amount - deductAmount).toFixed(2) : Number(amount).toFixed(2)),
        products: selectedProducts,
        tip_amount: tipAmount ? Number(tipAmount) : 0,
        personal_details: {
          firstName: firstName,
          lastName: lastName,
          email: emailprofile,
          mobileNo: mobileNo,
          pan: panNo,
          address: address,
          isIndian: isIndian ? "true" : "false",
        },
        is_anyomouns: anonymous,
        is_whatsup_update: updates,
      };

      if (selectedAddressId !== null) {
        requestData.billing_address = selectedAddressId;
      }

      let total = (parseFloat(deductTip ? amount - deductAmount : amount) + parseFloat(tipAmount)).toFixed(2);

      localStorage.setItem("Thank_You", true);
      localStorage.setItem("payment_amount", total);
      localStorage.setItem("user_name", firstName + " " + lastName);
      localStorage.setItem("organisation_name", selectedDonation.temple.name);
      new GTM().initiateCheckout({
        email: requestData.personal_details.email,
        totalAmount: donationAmount ? Number(donationAmount) : 0,
        sevaId: donate_id,
        slug: slug,
        quantity: selectedProducts?.length || 1,
        name: selectedDonation.name,
        products: selectedProducts || [],
      });
      const { data } = await noauthinstance.post("customer/order/create/", requestData);

      const personalDetailsString = data.data.personal_details.replace(/'/g, '"');
      localStorage.setItem("transactionId", data.razorpay_order.id);

      let UserData = JSON.parse(personalDetailsString);
      const options = {
        key: data.razorpay_order.RAZORPAY_API_KEY,
        amount: data.razorpay_order.amount,
        currency: data.razorpay_order.currency,
        name: "Veda Sankalpa",
        description: "Total Amount",
        image: { logoIcon },
        order_id: data.razorpay_order.id,
        handler: function (response) {
          sendPaymentStatusToServer(data, response, "Success");
        },
        modal: {
          ondismiss: function () {
            sendPaymentStatusToServer(data, null, "Cancelled");
          },
        },
        prefill: {
          name: UserData.firstName,
          email: UserData.email,
          contact: UserData.mobileNo,
        },
        redirect: true,
        callback_url: "https://backend.vedasankalpa.com/v1/customer/order/server_update/",
        retry: {
          enabled: false,
        },
        timeout: 900,
        theme: {
          color: "#c84204",
        },
      };
      const razor = new window.Razorpay(options);
      razor.on("payment.failed", function (response) {
        sendPaymentStatusToServer(data, response, "Failed");
      });
      razor.open();

      setSelectedProducts("");
    } catch (error) {
      toast.error("Error creating order:", error);
    }
  };

  useEffect(() => {
    if (checkoutSuccess && !showModal1) {
      navigate("/");
    }
  }, [checkoutSuccess, showModal1]);

  const handleTooltip = () => {
    if (!firstName || !lastName || !mobileNo || (selectedAddressId === null && showAddress === true) || isIndian === null) {
      if (selectedAddressId === null && showAddress === true) {
        toast.error("Please select address");
        return;
      } else if (!firstName || !lastName || !mobileNo) {
        toast.error("Please fill all required personal fields");
        return;
      } else if (isIndian === null) {
        toast.error("Please select nationality");
        return;
      }
    } else {
      handleCheckout();
    }
  };
  const options = { maximumFractionDigits: 2 };
  return (
    <>
      <Header />

      <div className="top-stepper checout_top">
        <Container>
          <div className="top-stepper">
            {steps.map((label, index) => (
              <div key={label} style={{ display: index === activeStep ? "block" : "none" }}>
                {index === 0 && (
                  <div className="check_out_1">
                    <div className="check_out_content">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={8}>
                          <div className="mycart_title">
                            <h3>Checkout</h3>
                          </div>
                          <div className="mycart_bg">
                            <div className="mycart_bg_seva_name">
                              <div className="mycart_bg_seva_name_list">
                                <div className="mycart_bg_seva_name_list_item">
                                  <img src={`${selectedDonation?.banner_image?.base_url}${selectedDonation?.banner_image?.image_path}`} alt="Banner" />
                                </div>
                                <div className="mycart_bg_seva_name_list_item_1">
                                  {selectedDonation && (
                                    <>
                                      <h3>{selectedDonation.name}</h3>
                                      <h4>{formatDate(selectedDonation.start_date)}</h4>
                                      <h5>
                                        Location - <span>{selectedDonation.location}</span>
                                      </h5>
                                      {amount ? (
                                        <p>
                                          ₹ {Intl.NumberFormat("en-IN", options).format(amount)}
                                        </p>
                                      ) : (
                                        <p>Loading...</p>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="mycart_remove remove_sm">
                                <Button onClick={handleRemoveClick}>Remove</Button>
                              </div>
                            </div>
                            <div className="dakshana_bg tip_bg">
                              {showDakshanaOptions && (
                                <div className="dakshana-select">
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Tip amount</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedDakshana} onChange={handleChangeDaskshana} label="Select Tip amount">
                                      {dakshanaOptions.map((option) => {
                                        const percentage = parseFloat(option.value) / 100;
                                        const calculatedAmount = amount ? amount * percentage : 0;
                                        return (
                                          <MenuItem key={option.id} value={option.value}>
                                            {option.value}% - ₹{calculatedAmount.toFixed(2)}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              )}
                              {selectedDakshana === "other" && !deductTip && showDakshanaOptions && (
                                <div className="custom-amount-input tip_amount">
                                  <div className="amount-input_inputWrapper">
                                    <span className="rupee-symbol">₹</span>
                                    <TextField label="Enter Tip" type="number" value={customAmount} onChange={handleCustomAmountChange} />
                                  </div>
                                </div>
                              )}

                              {deductTip || !showDakshanaOptions ? (
                                <div className="tip_amount_details">
                                  <Switch checked={deductTip} onChange={(e) => handleDeductTipChange(e.target.checked)} name="tipDeduction" color="primary" />
                                  Deduct 7% from Donation amount (optional)
                                </div>
                              ) : null}
                              <div className="tip_amount_details_text">
                                Your tip will help us support more stakeholders of Sanatana Dharma. Giving is Godly!
                              </div>
                            </div>
                            <Paper sx={{ mt: 2 }} elevation={0} className="card-box">
                              <Accordion className="personal-info" defaultExpanded={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                  <div className="personal_info_check_out" onClick={(e) => e.stopPropagation()}>
                                    Personal Information
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="personal_information_form">
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField error={error?.first} id="outlined-basic" label="First Name *" variant="outlined" value={firstName} onChange={(e) => updateName(e.target.value, "first")} />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField error={error?.last} id="outlined-basic" label="Last Name *" variant="outlined" value={lastName} onChange={(e) => updateName(e.target.value, "last")} />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField error={error?.email} id="outlined-basic" label="Email" variant="outlined" value={emailprofile} onChange={(e) => updateEmailProfile(e.target.value)} />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField id="outlined-basic" label="Mobile No *" variant="outlined" error={error?.mobile} value={mobileNo} onChange={(e) => updateMobile(e.target.value)} />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <RadioGroup className="india_nonindia" aria-label="indian" name="indian" value={isIndian === null ? "" : isIndian ? "indian" : "notIndian"} onChange={handleIndianChange} style={{ display: "flex", flexDirection: "row" }}>
                                          <FormControlLabel value="indian" control={<Radio />} label="I'm Indian National" />
                                          <FormControlLabel value="notIndian" control={<Radio />} label="Non Indian National" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                    <div className="checkbox_for_anonymous">
                                      <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={anonymous} onChange={handleAnonymousChange} />} label="Make my donation anonymous" />
                                        <FormControlLabel control={<Checkbox checked={updates} onChange={handleUpdatesChange} />} label="I want to receive transaction and donation updates on WhatsApp" />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Paper>
                            {showAddress && (
                              <Paper sx={{ mt: 2 }} elevation={0} className="card-box">
                                <Accordion defaultExpanded={true} className="personal-info">
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                    Add Billing Address *
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="personal-info-mian">
                                      {addresses.map((address, index) => (
                                        <div className="personal-info-content" key={index}>
                                          <Grid container spacing={2}>
                                            <div className="add-family">
                                              <div className="add-family-text-1">
                                                <Checkbox onChange={() => handleAddressSelection(address.id)} />
                                                <div>
                                                  <Typography variant="h6">{address.address_type}</Typography>
                                                  <Typography variant="body1">
                                                    {address.address1}, {address.city}, {address.state}, {address.country}
                                                  </Typography>
                                                </div>
                                              </div>
                                              <div className="personal-info-mian_edit_buton">
                                                <Button onClick={() => handleEditAddress(address.id)}>
                                                  Edit
                                                  <img src={edit} alt="" />
                                                </Button>
                                              </div>
                                            </div>
                                          </Grid>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="add-address">
                                      <Typography variant="h5">Add New Address</Typography>
                                      <Button onClick={handleAddAddress}>
                                        Add
                                        <AddIcon />
                                      </Button>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </Paper>
                            )}

                            <Dialog className="campaigner-modal" fullWidth maxWidth={"sm"} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                              <DialogTitle className="puja_home_title">
                                <Typography variant="h6">{modalMode1 === "add" ? "Add Address" : "Edit Address"}</Typography>
                              </DialogTitle>
                              <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                                <CloseOutlined />
                              </IconButton>
                              <DialogContent>
                                <div className="personal-info">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <TextField id="fullName" label="Full Name *" value={fullName} onChange={(e) => setFullName(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <TextField id="mobileNumber" label="Mobile Number *" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <TextField id="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <TextField id="whatsappNo" label="Alternate No *" value={whatsappNo} onChange={(e) => setWhatsappNo(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                      <TextField id="addressLine1" label="Address Line 1 *" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                      <TextField id="addressLine2" label="Address Line 2" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <FormControl fullWidth>
                                        <TextField id="country" label="Country *" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)} variant="outlined" fullWidth />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <FormControl fullWidth>
                                        <TextField id="state" label="State *" value={selectedState} onChange={(e) => setSelectedState(e.target.value)} variant="outlined" fullWidth />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <FormControl fullWidth>
                                        <TextField id="city" label="City *" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)} variant="outlined" fullWidth />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <TextField id="pinCode" label="Pin Code *" value={pinCode} onChange={(e) => setPinCode(e.target.value)} variant="outlined" fullWidth />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <FormControl fullWidth>
                                        <InputLabel id="addressType-label">Address Type *</InputLabel>
                                        <Select labelId="addressType-label" id="addressType-select" value={addressType} onChange={(e) => setAddressType(e.target.value)} variant="outlined" label="Address Type *">
                                          <MenuItem value="">Select Address Type</MenuItem>
                                          <MenuItem value="Home">Home</MenuItem>
                                          <MenuItem value="Work">Work</MenuItem>
                                          <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </div>
                              </DialogContent>
                              <DialogActions>
                                <div className="rest_save_button">
                                  <Button disableRipple disableElevation className="rest" onClick={clearForm}>
                                    Discard
                                  </Button>
                                  <Button disableRipple disableElevation className="save" onClick={() => handleSaveAddress()}>
                                    Save Address
                                  </Button>
                                </div>
                              </DialogActions>
                            </Dialog>
                          </div>
                          <span className="back_next_buttons donation_anonymous_buttons">
                            <div className="next_button1 donation_anonymous">
                              <Tooltip title={!firstName || !lastName || !mobileNo || (selectedAddressId === null && showAddress === true) ? selectedAddressId === null && showAddress === true ? "Please select address" : "Please fill all required personal fields" : isIndian === null ? "Please select nationality" : ""} placement="top" disableHoverListener={firstName && firstName.trim() === "" && lastName && lastName.trim() == "" && mobileNo && isIndian !== null && selectedAddressId}>
                                <span className="back_next_buttons donation_anonymous_buttons">
                                  <div className="next_button1 donation_anonymous">
                                    <Button title="Please fill all required personal fields" onClick={handleCheckout} disableRipple disableElevation disabled={!firstName || !lastName || !mobileNo || !emailprofile || isIndian === null || (showAddress === true && !selectedAddressId) || firstName.trim() === "" || lastName.trim() === "" || proceedPay}>
                                      <img className="right_symbol" src={right_symbol} alt="" />
                                      ₹{Intl.NumberFormat("en-IN", options).format(parseFloat(deductTip ? amount - deductAmount : amount) + parseFloat(tipAmount))} Proceed to pay
                                      <img className="right_sm_arrow" src={right_sm_arrow} alt="" />
                                    </Button>
                                  </div>
                                </span>
                              </Tooltip>
                            </div>
                            {checkoutSuccess && <ThankYou open={true} />}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <div className="summary-bg">
                            <p className="summary_title">Summary</p>
                            <div className="cost_title">
                              <div className="cost-title">
                                <p>Sub Total</p>
                              </div>
                              <div className="cost">
                                <p>₹{Intl.NumberFormat("en-IN", options).format(deductTip ? amount - deductAmount : Number(amount))}</p>
                              </div>
                            </div>
                            <div className="cost_title">
                              <div className="cost-title">
                                <p>Tip</p>
                              </div>
                              <div className="cost">
                                <p>₹{Intl.NumberFormat("en-IN", options).format(tipAmount)}</p>
                              </div>
                            </div>
                            <div className="cost_title">
                              <div className="cost">
                                <p>Total</p>
                              </div>
                              <div className="cost">
                                <p>₹{Intl.NumberFormat("en-IN", options).format(parseFloat(deductTip ? amount - deductAmount : amount) + parseFloat(tipAmount))}</p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="back_next_buttons donation_anonymous_buttons">
                        <Tooltip title={!firstName || !lastName || !mobileNo || (selectedAddressId === null && showAddress === true) ? selectedAddressId === null && showAddress === true ? "Please select address" : "Please fill all required personal fields" : isIndian === null ? "Please select nationality" : ""} placement="top" disableHoverListener={firstName && firstName.trim() === "" && lastName && lastName.trim() == "" && mobileNo && isIndian !== null && selectedAddressId}>
                          <span>
                            <div className="donations_count">
                              <div className="donations">
                                <div className="donations_count_main next_button1">
                                  <Button
                                    onClick={() => {
                                      setThankYou(false);

                                      handleTooltip();
                                    }}
                                    disableRipple
                                    disableElevation
                                    disabled={!firstName || !lastName || !mobileNo || !emailprofile || isIndian === null || (showAddress === true && !selectedAddressId) || firstName.trim() === "" || lastName.trim() === "" || proceedPay}
                                  >
                                    <img className="right_symbol" src={right_symbol} alt="" />
                                    ₹{Intl.NumberFormat("en-IN", options).format(parseFloat(deductTip ? amount - deductAmount : amount) + parseFloat(tipAmount))} Proceed to pay
                                    <img className="right_sm_arrow" src={right_sm_arrow} alt="" />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div className="">
        <Footer />
      </div>
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank" class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
        <img alt="whatsapp" src={whatsapp} />
      </a>
    </>
  );
}
export default Donation_checkout;
