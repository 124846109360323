import React from "react";
import video_section from '../assets/images/details_puja/video_section.png';
import play_button from '../assets/images/details_puja/icons/play.svg'
import { Button, Typography } from "@mui/material";

//npm i react-video-js-player
import ReactPlayer from 'react-player';

function Video({sevavideo}){
    console.log(sevavideo)
    return(
        <>
        {sevavideo.map((video, index) => (
         <div className="video-section">
            {/*
                <img src={video_section} alt="" />
                    <div className="video-_section_button">
                        <Button disableRipple disableElevation>
                            <img src={play_button}></img>
                        </Button>
                     </div>
            </div> */}
             <div className="video_section_image">
                <ReactPlayer className="video_section-content" url={video.video_url} />
            </div>
            <Typography variant="h4">{video.name}</Typography>
            <Typography variant="body1">{video.description}</Typography>
        </div>
               ))}
        </>
    )
}
export default Video;